import styled from "styled-components";

const Citizens = () => {

    const citizenarray = [
        {
            name: "Philip Bassey",
            email: "sunnyphilip51@gmail.com",
            role: "Manager",
            lga: "Oshodi - Isolo",
            date: "12/12/22",
        },
        {
            name: "Philip Bassey",
            email: "sunnyphilip51@gmail.com",
            role: "Manager",
            lga: "Oshodi - Isolo",
            date: "12/12/22",
        },
        {
            name: "Philip Bassey",
            email: "sunnyphilip51@gmail.com",
            role: "Manager",
            lga: "Oshodi - Isolo",
            date: "12/12/22",
        },
        {
            name: "Philip Bassey",
            email: "sunnyphilip51@gmail.com",
            role: "Manager",
            lga: "Oshodi - Isolo",
            date: "12/12/22",
        },
        {
            name: "Philip Bassey",
            email: "sunnyphilip51@gmail.com",
            role: "Manager",
            lga: "Oshodi - Isolo",
            date: "12/12/22",
        },
        {
            name: "Philip Bassey",
            email: "sunnyphilip51@gmail.com",
            role: "Manager",
            lga: "Oshodi - Isolo",
            date: "12/12/22",
        },
        {
            name: "Philip Bassey",
            email: "sunnyphilip51@gmail.com",
            role: "Manager",
            lga: "Oshodi - Isolo",
            date: "12/12/22",
        },
        {
            name: "Philip Bassey",
            email: "sunnyphilip51@gmail.com",
            role: "Manager",
            lga: "Oshodi - Isolo",
            date: "12/12/22",
        }
    ]
    return ( 
        <Con>
            <header>
                <h2>Alaje Citizen</h2>
                <button><p>Add Users</p><img src="/images/dashboard/arrow-right-white.svg" alt="icon" /></button>
            </header>

            <div className="header">
                <p>NAME</p>
                <p id="role">ROLE</p>
                <img src="/images/dashboard/actionque.svg" alt="img" />
                <p id="lga">LGA</p>
                <p id="date">DATE JOINED</p>
            </div>

            <main>
                {citizenarray.map(({name, role, lga, email, date})=> (
                    <div className="box">
                       <img src="/images/dashboard/platUser.svg" alt="ing" />
                       <div className="name-email">
                            <p id="name">{name}</p>
                            <p id="email">{email}</p>
                       </div>
                       <p id="role">{role}</p>
                       <p id="lga">{lga}</p>
                       <p id="date">{date}</p>

                       <button>More</button>
                    </div>
                ))}
            </main>
        </Con>
     );
}

const Con = styled.div`
  padding-left: 27px;
  padding-top: 40px;
  height: 100vh;
 
     header {
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 17px 24px;
         width: 1159px;
         height: 78px;
         background: #fff;
         border: 1px solid #E4E7F8;
         border-radius: 4px;
 
         h2 {
             font-weight: 500;
             font-size: 28px;
             line-height: 42px;
             color: #0B0B0B;
         }
 
         button {
             display: flex;
             align-items: center;
             padding: 14.5px 30.5px;
             gap: 10px;
             width: 185px;
             height: 44px;
             background: #DF9E30;
             border-radius: 2px;
             color: #fff;
             border: none;
 
             p {
                 font-weight: 600;
                 font-size: 15px;
             }
 
             img {
                 color: #fff;
                 margin-top: -7px;
             }
         }
     }

     .header {
        display: flex;
        align-items: center;
        margin-top: 32px;
        flex-direction: row;
        align-items: center;
        padding: 24px 14px;
        width: 1159px;
        height: 44px;
        border: 1px solid #E4E7F8;
        border-radius: 4px;

        #role {
            margin-left: 290px;
        }
        img {
            margin-left: 3px;
            margin-top: -6px;
        }
        #lga {
            margin-left: 149px;
        }
        #date {
            margin-left: 231px;
        }
     }

     .box {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        width: 1159px;
        height: 72px;
        border: 1px solid #E4E7F8;
        border-top: none;

        .name-email {
            margin-left: 1rem;
            margin-top: 3px;

            #name {
                width: 89px;
                height: 17px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #23252F;
            }

            #email {
                width: 146px;
                height: 15px;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #575C76;
            }
        }

        #role {
            width: 55px;
            height: 18px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #667085;
            margin-left: 112px;
        }

        #lga {
            width: 89px;
            height: 18px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #667085;
            margin-left: 145px;
        }

        #date {
            margin-left: 174px;
            width: 62px;
            height: 18px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #667085;
        }

        button {
            padding: 7px 21px;
            width: 74px;
            height: 32px;
            border: 1px solid #C8CFF2;
            border-radius: 4px;
            margin-left: 200px;
            background: transparent;
            cursor: pointer;
        }
     }
`


 
export default Citizens;