import styled from "styled-components";
import React from "react";
import { useState } from "react";
import Grid from "./Grid";
import List from "./List";
import Block from "./Block";


const Index = ( { data }: any ) => {

    const [view, setView] = useState(1);

    return ( 
        <Con>
             <header>
                 <h2>Platform Management</h2>
                 <button><p>Add platform</p><img src="/images/dashboard/arrow-right-white.svg" alt="icon" /></button>
             </header>
 
             <DisplayNav>
                 <div className="search">
                     <input type="search" name="" id="" placeholder="Search by name" />
                     <img src="/images/dashboard/search.svg" alt="icon" />
                 </div>
                 <div className="view">
                     <img src="/images/dashboard/listview.svg" alt="icon" onClick={() => setView(1)}/>
                     <img src="/images/dashboard/gridview.svg" alt="icon" onClick={() => setView(2)}/>
                 </div>
             </DisplayNav>

             <DisplayArea>
                {view==1 && <List/>}
                {view==2 && <Grid/>}
             </DisplayArea>
        </Con>
     );
}

const Con = styled.div`
  padding-left: 27px;
  padding-top: 40px;
  height: 100vh;
 
     header {
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 17px 24px;
         width: 1159px;
         height: 78px;
         background: #fff;
         border: 1px solid #E4E7F8;
         border-radius: 4px;
 
         h2 {
             font-weight: 500;
             font-size: 28px;
             line-height: 42px;
             color: #0B0B0B;
         }
 
         button {
             display: flex;
             align-items: center;
             padding: 14.5px 30.5px;
             gap: 10px;
             width: 185px;
             height: 44px;
             background: #DF9E30;
             border-radius: 2px;
             color: #fff;
             border: none;
 
             p {
                 font-weight: 600;
                 font-size: 15px;
             }
 
             img {
                 color: #fff;
                 margin-top: -7px;
             }
         }
     }
`


const DisplayNav = styled.div`
padding-right: 35px;
margin-top: 24px;
display: flex;
align-items: center;
justify-content: space-between;


.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    width: 338px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E4E7F8;
    border-radius: 4px;

        input {
            width: 280px;
            outline: none;
            border: none;
        }

}

.view {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px;
    gap: 16px;
    width: 80px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #EEECF6;
    border-radius: 4px;

    img {
        cursor: pointer;
    }
}

`

const DisplayArea = styled.div`

`
 
export default Index;