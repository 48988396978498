import React from 'react'
import styled from "styled-components";
import { ReactComponent as Logo } from "./images/AuthLogo.svg"

const AuthLayout: React.FC<any> = ({ children }) => {
  return (
    <Wrapper>
      <a href="/"><Logo className='logo' /></a>
      <div className="children">
        {children}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    background: #FAFAFA;
    padding: 20px;
    

    .logo{
      width: 50px;
    }

    .children{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 100px;
    }
`;

export default AuthLayout
