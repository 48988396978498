import Styles from "../styles/Styles";
import { Divider, Input } from "antd";
import { ReactComponent as Mail } from "../images/Mail.svg";
import { FaKey, FaLongArrowAltRight } from "react-icons/fa";
import * as yup from "yup"
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { toast } from "react-toastify";
import { ReactComponent as NigerFlag } from "../images/NigerFlag.svg";


interface FormData {
    mode?: string,
    username: string,
    password: string
}

const initialValues: FormData = {
    mode: "EMAIL",
    username: "",
    password: ""
}

const validationSchema = yup.object().shape({
    mode: yup.string().required("Mode cannot be blank"),
    username: yup.string()
        .when("mode", {
            is: (val: any) => val == "EMAIL",
            then: yup.string().required("Email cannot be blank").email("Email is not valid"),
            otherwise: yup.string().required("Mobile Number cannot be blank"),
        }),
    password: yup.string().required("Password cannot be blank")
})


export default function Login() {
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (fieldData: FormData, helpers) => {
            try {
                let {
                    username, password
                } = fieldData
                const loginData = new URLSearchParams()
                loginData.append('username', username)
                loginData.append('password', password)
                loginData.append('grant_type', process.env.REACT_APP_GRANTTYPE as string)

                // let { data } = await login(loginData)
                // setAccessToken(data.access_token)
                // localStorage.setItem('loginData', JSON.stringify(data))

                window.location.href = '/dashboard'
            } catch (error: any) {
                toast.error(
                    error?.response?.data?.error_description || 'An error occured'
                )
            }
        },
        validationSchema
    })


    return <Styles>
        <div className="card-section">
            <h3>Login</h3>
            <FormikProvider value={formik}>
                <p className="subHeader">
                    Welcome back! Login to your account and continue where you left off.
                </p>
                <Input
                    placeholder={formik.values.mode === "EMAIL" ? "Email Address" : "Mobile Number"}
                    prefix={formik.values.mode === "EMAIL" ? <Mail className="icon" /> : <NigerFlag className="icon" />}
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="large" className="mt-3 input-field" />
                <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='username' />


                <Input.Password
                    placeholder="Password"
                    name="password"
                    prefix={<FaKey style={{ color: "#858585" }} className="icon" />}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="large" className="mt-3 input-field" />
                <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='password' />

                <div className="d-flex justify-content-center my-3">
                    <a href="/auth/forgot-password" className="login underline">Forgot Password?</a>
                    <p className="or-register ml-2 mb-0" onClick={() => {
                        formik.setFieldValue("username", "")
                        return formik.setFieldValue("mode", formik.values.mode === "EMAIL" ? "MOBILE_NUMBER" : "EMAIL")
                    }}>Or login with {formik.values.mode === "EMAIL" ? "Mobile Number" : "Email"}</p>
                </div>
                <button
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="button"
                    onClick={() => formik.submitForm()}
                    className="ct-button mt-4 w-100">
                    {formik.isSubmitting ? <span className='spinner-border spinner-border-sm text-white'></span> : "Continue"}
                </button>
                <div className="d-flex justify-content-center mt-4">
                    <a href="/auth/register" className="login">Register <FaLongArrowAltRight className="ml-2" /></a>
                </div>
            </FormikProvider>
        </div>
    </Styles >
}