import { Modal } from 'reactstrap'
import { FaTimesCircle } from "react-icons/fa"
import ModalStyle from './ModalStyles'
import { useState } from "react"
import { ReactComponent as GoBackIcon } from "../images/modalBack.svg"
import Select from "react-select"
import { FormGroup } from "reactstrap"
import ReactSelectStyling from 'components/common/ReactSelectStyling'


export default function AddUserModal({ addUser, setAddUser }: any) {
    const [state, setState] = useState({
        payingFor: "",
        stage: 1,
        paymentMethod: "Card"
    })


    return <>
        <Modal isOpen={addUser} toggle={setAddUser} size='md'>
            {addUser &&
                <ModalStyle>
                    <div className="d-flex align-items-center border-bottom mb-3 pb-4 pt-2">
                        {state.stage === 1 ?
                            <h6 className="bold-700 mb-0">Add User</h6> :
                            <h6 onClick={() => setState(prev => ({ ...prev, stage: prev.stage - 1 }))}
                                className="cursor-pointer bold-700 mb-0">
                                <GoBackIcon className='mr-2' />Go Back
                            </h6>}
                        <button onClick={setAddUser} className="ml-auto close-btn">
                            <small>Close</small><FaTimesCircle />
                        </button>
                    </div>

                    {state.stage === 1 &&
                        <>
                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Employee’s Name</label>
                                <input className='ct-input' placeholder='Enter Name' />
                            </FormGroup>

                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Employee’s Email Address</label>
                                <input className='ct-input' placeholder='Enter Email Address' />
                            </FormGroup>

                            <FormGroup>
                                <label htmlFor="">Employee’s Role</label>
                                <Select styles={ReactSelectStyling()} />
                            </FormGroup>
                        </>}

                    <div className="d-flex align-items-center mt-4">
                        <button onClick={() => { }} className="next-btn w-100">
                            Add User
                        </button>
                    </div>

                </ModalStyle>}
        </Modal>
    </>
}