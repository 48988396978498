import { Avatar } from "antd";
import DashboardTableStyle from "assets/scss/components/DashboardTableStyle";

export default function AllUsers({ setState }: any) {
    return <DashboardTableStyle>
        <div className="table-responsive">
            <table className="w-100 h-100">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>LGA</th>
                        <th>Date Joined</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {[1, 2, 3, 4, 2].map((val, idx) =>
                        <tr key={idx}>
                            <td>
                                <div className="employee">
                                    <Avatar src={"https://xsgames.co/randomusers/avatar.php?g=male"} size={35} />
                                    <div className="info">
                                        <p className="name">Philip Bassey</p>
                                        <small>sunnyphilip51@gmail.com</small>
                                    </div>
                                </div>
                            </td>
                            <td>Manager</td>
                            <td>Oshodi - Isolo</td>
                            <td>12/12/22</td>
                            <td className="btns">
                                <span onClick={() => setState((prev: any) => ({ ...prev, moreAction: true }))}>More</span>
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </div>
    </DashboardTableStyle>
}