import styled from "styled-components";
import help1 from '../../../assets/help1.svg'
import help2 from '../../../assets/help2.svg'
import help3 from '../../../assets/help3.svg'
import help4 from '../../../assets/help4.svg'
import help5 from '../../../assets/help5.svg'

const SupportActivities = ({display, setDisplay}: any) => {

    const helpArray = [
        {
            header: "How to manage your subscription",
            content:"Dui spien eget mi proin sed libero enim. Ultrices tincidunt arcu non sodales neque sodales ut etiam sit. Tristique magna sit amet purus gravida quis blandit. Sit amet consectetur adipiscing elit duis tristique sollicitudin. ",
            more:"Read more ...",
            img: help4
        },
        {
            header: "How to manage your subscription",
            content:"Dui sapien eget mi proin sed libero enim. Ultrices tincidunt arcu non sodales neque sodales ut etiam sit. Tristique magna sit amet purus gravida quis blandit. Sit amet consectetur adipiscing elit duis tristique sollicitudin. ",
            more:"Read more ...",
            img: help2
        },
        {
            header: "How to manage your subscription",
            content:"Dui sapien eget mi proin sed libero enim. Ultrices tincidunt arcu non sodales neque sodales ut etiam sit. Tristique magna sit amet purus gravida quis blandit. Sit amet consectetur adipiscing elit duis tristique sollicitudin. ",
            more:"Read more ...",
            img: help3
        },
        {
            header: "How to manage your subscription",
            content:"Dui sapien eget mi proin sed libero enim. Ultrices tincidunt arcu non sodales neque sodales ut etiam sit. Tristique magna sit amet purus gravida quis blandit. Sit amet consectetur adipiscing elit duis tristique sollicitudin. ",
            more:"Read more ...",
            img: help4
        },
        {
            header: "How to manage your subscription",
            content:"Dui sapien eget mi proin sed libero enim. Ultrices tincidunt arcu non sodales neque sodales ut etiam sit. Tristique magna sit amet purus gravida quis blandit. Sit amet consectetur adipiscing elit duis tristique sollicitudin. ",
            more:"Read more ...",
            img: help5
        },
    ]

    return (
     <Con>
        <nav>
            <div className="path">
                <img src="/images/dashboard/arrow-left.svg" alt="img" onClick={() => setDisplay(1)} />
                <p>Support Portal/Knowledge base/<span>Categories</span></p>
            </div>
            <div className="filter">
                <p>Filter:</p>
                <div className="box">
                    <p>2023</p>
                    <img src="/images/dashboard/arrown-down.svg" alt="img" />
                </div>
            </div>
        </nav>

        {/* main */}

        <Main>
            <aside>
                 <div className="search-box">
                    <img src="/images/dashboard/search.svg" alt="img" />
                    <input type="search" placeholder="Search..." />
                </div>

                <div className="help-list">
                    <p id="support">Support Articles</p>
                    <p id="faq">FAQs</p>
                    <p id="user">User guides</p>
                    <p id="guide">Troubleshoot guide</p>
                    <p id="blogs">Blogs</p>
                </div>
            </aside>

            <div className="help-articles">
                    {helpArray.map(({header, content, more, img})=> (
                            <div>
                                <div className="help-box">
                                    <div className="left">
                                        <h3>{header}</h3>
                                        <p>{content}</p>
                                        <small className="more" onClick={() => setDisplay(3)}>{more}</small>
                                    </div>
                                    <div className="right">
                                        <img src={img} alt="img" />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
        </Main>
     </Con>
     );
}

const Con = styled.div`
    margin-top: 39.5px;

   nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .path {
        display: flex;
        align-items: center;
        gap: 1rem;

        p {
            padding: 10px;
            width: 342px;
            height: 37px;
            background: #FFFFFF;
            border: 1px solid #EEECF6;
            border-radius: 4px;

            span {
                font-weight: 700;
            }
        }
    }

    .filter {
        display: flex;
        align-items: center;
        gap: 10px;

        .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;          
            width: 200px;
            height: 40px;
            border: 1px solid #EEECF6;
            border-radius: 4px;
        }
    }
   }
`

const Main = styled.div`
   margin-top: 43px;
   display: flex;
   gap: 24px;

   aside {
    width: 240px;
    height: 1059px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;



        .search-box {
            width: 192px;
            height: 42px;
            background: #F3F4F5;
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 19px;
            gap: 9px;
            font-size: 14px;

            input {
                width: 100px;
                outline: none;
                border: none;
                margin-top: 5px;
                background: #F3F4F5;
                display: flex;
                align-items: center;
            }
        }

        .help-list {
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid #E7E9EB;

            p {
                margin-top: 14px;
                margin-bottom: 18px;
                font-weight: 400;
                font-size: 14px;
            }

            #support {
                width: 192px;
                height: 45px;
                background: #FFF5E3;
                border-radius: 8px;
                display: flex;
                justify-content: left;
                padding-left: 16px;
                align-items: center;
                font-weight: 800;
                font-size: 14px;
            }
        }
    
   }

   .help-articles {
    width: 1200px;
    background: #FFF;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;

    .help-box {

        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        padding: 16px;
        border: 1px solid #EEECF6;
        border-radius: 4px;
        margin-top: 20px;


        .left {
            h3 {
                font-weight: 800;
                font-size: 18px;
                line-height: 120%;
                color: #14213D;
            }
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 168%;
                color: #434D64;
            }
            small {
                font-weight: 800;
                font-size: 14px;
                line-height: 120%;
                color: #DF9E30;
                cursor: pointer;
            }
        }
    }
   }
`
 
export default SupportActivities;