import { Avatar } from "antd";
import DashboardTableStyle from "assets/scss/components/DashboardTableStyle";

export default function RecentlyAddedUsers() {
    return <>
        <h6 className="ii-card-header">Recently added users</h6>
        <DashboardTableStyle>
            <div className="table-responsive">
                <table className="w-100 h-100">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Department</th>
                            <th>Date Joined</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[1, 2, 3, 4, 2].map((val, idx) =>
                            <tr key={idx}>
                                <td>
                                    <div className="employee">
                                        <Avatar src={"https://xsgames.co/randomusers/avatar.php?g=male"} size={35} />
                                        <div className="info">
                                            <p className="name">Philip Bassey</p>
                                            <small>sunnyphilip51@gmail.com</small>
                                        </div>
                                    </div>
                                </td>
                                <td>Manager</td>
                                <td>Engineering</td>
                                <td>12/12/22</td>
                                <td className="btns">
                                    <span>More</span>
                                    <span className="delete ml-2">Deactivate</span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </DashboardTableStyle>
    </>
}