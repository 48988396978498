import styled from "styled-components";

const SideModalHeader = styled.div` 
    width: 100%;
    height: 100%;        
    display:flex;
    justify-content:space-between; 
    align-items:center;  
    h1,p{
        margin-bottom:0;
    } 
    h1{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px; 
        color: #181818;

    }
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%; 
        color: #343434;
        cursor:pointer;

    }
`;

export default SideModalHeader