import Styles from "../styles/Styles";
import { ReactComponent as Envelope } from "../images/Envelope.svg"
import { Input } from "antd";
import { FaLongArrowAltRight } from "react-icons/fa";

export default function OTP() {
    return <Styles>
        <div className="card-section">
            <h3 className="d-flex align-items-center">
                OTP Verification
            </h3>

            <Input
                placeholder="Enter verification code"
                size="large" className="mt-3 input-field" />
            <p className="or-register my-3">Didn’t get an OTP? Resend after - 00:25</p>
            <button disabled className="ct-button mt-4 w-100">Continue</button>
            <div className="d-flex justify-content-center mt-4">
                <a href="/" className="login">Login <FaLongArrowAltRight className="ml-2" /></a>
            </div>
        </div>
    </Styles >
}