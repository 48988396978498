import { FaEllipsisV } from "react-icons/fa"
import styled from "styled-components"
import CustomChart from "./CustomChart"

export default function UsageAnalytics() {
    return <MainStyle className="p-3">
        <div className="d-flex align-items-center mb-4">
            <h6 className="bold-700">Usage analytics</h6>
            <FaEllipsisV className="ml-auto cursor-pointer" />
        </div>
        <CustomChart />
    </MainStyle>
}

const MainStyle = styled.div`
    background: #FFFFFF;
    border: 1px solid #E4E7F8;
    border-radius: 4px;
`