import { Modal, Avatar, Button } from 'antd';
import { FaTimes } from "react-icons/fa";
import styled from 'styled-components';


const Block = ({ visible, onCancel }: any) => {
    return <Modal
        title=""
        footer={null}
        closable={false}
        centered
        open={visible}
        width={415}
        bodyStyle={{ padding: 10, borderRadius: "4px", height: "290px", }}
        className="pipeline-card"
        onCancel={() => onCancel(true)} 
        onOk={() => onCancel(true)}
    >
       <Box>
           <div className="top">
                <h2>Block Platform</h2>

                <div className="closable">
                    <p>Close</p>
                    <Avatar onClick={() => visible(false)} size={20} style={{ background: "#343434", cursor: "pointer" }} icon={<FaTimes className="close-icon txt-primary txt-12 mt-n1" />} />
                </div>
           </div>

           <Content>
                <img src="/images/dashboard/warning.svg" alt="img" />
                <p>Are you sure you want to block the following platform <span style={{fontWeight: "700px"}}>- Alaje Citizen</span> ?</p>
           </Content>

           <Buttons>Block</Buttons>
       </Box>
    </Modal>
}

const Box = styled.div`
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E4E7F8;
        padding-bottom: 10px;

        h2 {
            font-weight: 500;
            font-size: 24px;
            color: #343434;
        }

        .closable {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
                font-weight: 400;
                font-size: 16px;
                color: #343434; 
                margin-top: 8px; 
            }

    }

`

const Content =styled.div`
        margin-top: 1rem;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-right: 18px;
        padding-left: 10px;
        gap: 10px;
        width: 350px;
        height: 110px;
        background: #FEFCF3;
        border-radius: 4px;

        p {
            width: 335px;
            height: 44px;
            font-weight: 400;
            font-size: 14px;
            color: #434D64;
            margin-top: 10px;

            .span {
                font-weight: 800px;
            }
        }
`

const Buttons = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14.5px 30.5px;
        gap: 10px;
        width: 367px;
        height: 44px;
        background: #181818;
        border-radius: 2px;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
`



 
export default Block;