export const dataKeys=["pv", "uv"]
export const data = [
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
    colors: ["#5141A4", "#CBC6E4"]
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
    colors: ["#5141A4", "#CBC6E4"]
  },
];

export const barData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const chartNames = [{ title: "Net Payments", color: "#D88B07" }, { title: "Gross Payments", color: "#DE9E30" },{ title: "Net", color: "red" }]
