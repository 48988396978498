import { Input } from "antd"
import Button from "components/ui/Button"
import { FaArrowRight, FaLongArrowAltRight, FaSearch } from "react-icons/fa"
import styled from "styled-components"

const Style = styled.div`
    background: #FFFFFF;
    border: 1px solid #E4E7F8;
    border-radius: 4px;
`
export default function Header({ setState, state }: any) {
  return <>
    <Style className="p-3 d-flex align-items-center">
      <h5 className="mb-0">Activity Log</h5>
      
    </Style>

    <div className="d-flex align-items-center">
      <TogglerStyle className='mt-5'>
        {["Portal", "Citizen", "Government"].map((item) =>
          <button
            onClick={() => setState((prev: any) => ({ ...prev, tab: item }))}
            className={`${state.tab === item && "active"}`}>
            {item}
          </button>
        )}
      </TogglerStyle>

    </div>
  </>
}



const TogglerStyle = styled.div`
    border-radius: 8px;
    margin-bottom: 48px;
    width: max-content;
    border: 1px solid #EEECF6;

    @media only screen and (max-width: 700px) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      button{
        width: 50%;
      }
    }
    button{
      color: #23252F;
      font-family: 'Plus Jakarta Text';
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      background: none;
      padding: 8px 24px;
      background: #FFFFFF;
      border-width: 1px 0px 1px 1px;
      border-style: solid;
      border-color: #D0D3D8;
      &:nth-of-type(1){
        border-radius: 8px 0px 0px 8px;
      }
      &:last-of-type{
        border-radius: 0px 8px 8px 0px;
        border-right-width: 1px;
      }
      &.active{
        background: #23252F;
        color: #FFFFFF;
      }
    }
`
