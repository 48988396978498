import React, { useState, useEffect, useCallback } from 'react'
import styled from "styled-components";
import { Radio, Tabs, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const PlatformManagement = () => {

    const [currentTab, setCurrentTab] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    return (
        <Con>
            <Tabs
                onChange={(key) => {
                    switch (key) {
                        case "1":
                            setCurrentTab(0)
                            break;
                        case "2":
                            setCurrentTab(1)
                            break;
                        default:
                            break;
                    }
                }}
                defaultActiveKey="1">
                <Tabs.TabPane tab="Citizen/Business" key="1" />
                <Tabs.TabPane tab="Approvals" key="2" />
            </Tabs>
            <SelRow>
                <div className='left'>
                    <div className={`${currentPage === 0 && "active"} first`} onClick={() => setCurrentPage(0)}>
                        <p>All Platforms</p>
                    </div>
                    <div className={`${currentPage === 1 && "active"} mid`} onClick={() => setCurrentPage(1)}>
                        <p>Approved</p>
                    </div>
                    <div className={`${currentPage === 2 && "active"} mid`} onClick={() => setCurrentPage(2)}>
                        <p>Pending</p>
                    </div>
                    <div className={`${currentPage === 3 && "active"} last`} onClick={() => setCurrentPage(3)}>
                        <p>Declined</p>
                    </div>
                </div>
                <div className='right'>
                    <DropBox>
                        <p>Filter by</p>
                        <img src="/images/dashboard/fullDrop.svg" alt="img" />
                    </DropBox>
                </div>
            </SelRow>
            <MyTable>
                <div className='head'>Transaction history</div>
                <tr>
                    <th>TRANSACTION ID</th>
                    <th>PAYMENT BY:</th>
                    <th>Received by:</th>
                    <th>BILL</th>
                    <th>MODE</th>
                    <th>DATE</th>
                    <th></th>
                </tr>
                <tr>
                    <td>
                        <ActivityRow>
                            <Avatar icon={<UserOutlined />} />
                            <div className='activity-det'>
                                <p className='name'>Demi Wikinson</p>
                                <p className='activity'>sunnyphilip51@gmail.com</p>
                            </div>
                        </ActivityRow>
                    </td>
                    <td>Philip</td>
                    <td>Bassey</td>
                    <td>Ichigo</td>
                    <td>Oshodi Isolo</td>
                    <td>Male</td>
                    <td><More>View </More></td>
                </tr>
                <tr>
                    <td>
                        <ActivityRow>
                            <Avatar icon={<UserOutlined />} />
                            <div className='activity-det'>
                                <p className='name'>Demi Wikinson</p>
                                <p className='activity'>sunnyphilip51@gmail.com</p>
                            </div>
                        </ActivityRow>
                    </td>
                    <td>Philip</td>
                    <td>Bassey</td>
                    <td>Ichigo</td>
                    <td>Oshodi Isolo</td>
                    <td>Male</td>
                    <td><More>View </More></td>
                </tr>
            </MyTable>
        </Con>
    )
}

const Con = styled.div`
  width: 100%;  
  .ant-tabs-tab { 
    font-style: normal;
    font-weight: 400;
    font-size: 13px; 
    color: #5C5C5C !important;
  
  } 
  
  .ant-tabs-tab:hover {
      color: #D88B07 !important;
  }
  
  .ant-tabs-tab-active {
    color: #D88B07 !important;
    font-style: normal;
    font-weight: 600;
    font-size: 13px; 
    
  }
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #D88B07 !important;  
  }
  
  .ant-tabs-ink-bar { 
    background: #D88B07 !important;
  }
`

const DropBox = styled.div`
    width: 143px;
    height: 37px; 
    background: #EFF1FB; 
    border: 1px solid #C8CFF2;
    border-radius: 2px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px; 
    color: #343434;
    padding:10px;
`;

const SelRow = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:20px;
    .left{
        display:flex;
        div{
            height: 40px; 
            padding: 10px 16px; 
            background: #FFFFFF;
            border:1px solid #D0D3D8;
            border-right:none;
            cursor:pointer;
            P{
                font-family: 'IBM Plex Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px; 
                color: #434D64;
    
            }
        }
        .first{
            border-radius: 4px 0px 0px 4px;
        }
        .mid{
    
        }
        .last{
            border-radius: 0px 4px 4px 0px;
            border:1px solid #D0D3D8;
        }
        .active{
            background: #23252F;
            p{
                font-family: 'IBM Plex Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px; 
                color: #FFFFFF;
            }
        }
    }

`;

const MyTable = styled.table`
    width: 100%; 
    border: 1px solid #E4E7F8;
    border-radius: 4px;
    background: #FFFFFF;
    .head{
      width:100%;
      height: 63px; 
      background: #FFFFFF;
      display:flex; 
      padding:20px;
      align-items:center;
      p{
        margin:0;
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px; 
        color: #5C5C5C;

      }
    }
    tr{
        border-bottom: 1px solid #E4E7F8; 
        th{
            text-align:left;
            padding:10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px; 
            color: #575C76;
            background: #F9FAFB;; 
        }
        td{
            padding:12px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px; 
            color: #575C76; 
            vertical-align: middle;
        }
        .last{
            display:flex;
            img{
                margin-left:10px;
            }
        }
    }
`;

const More = styled.button` 
  padding:7px 21px 7px 21px;
  height: 40px; 
  background: rgba(255, 255, 255, 0.17); 
  border: 1px solid #E4E7F8;
  border-radius: 4px; 
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; 
  color: #23252F; 
  img{
    margin-left:5px;
  }
`;

const ActivityRow = styled.div` 
    height:100%;
    display:flex;
    align-items:center; 
    .activity-det{
        margin-left:10px;
        .name{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px; 
            color: #23252F; 
        }
        .activity{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px; 
            color: #575C76;
        }
    }
`

export default PlatformManagement