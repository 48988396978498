import styled from "styled-components";

const Button = styled.button`
    all: unset;
    padding: 10px 30.5px;
    background: #DF9E30;
    border-radius: 2px;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
`

export default Button