import { Modal } from 'reactstrap'
import { FaTimesCircle } from "react-icons/fa"
import ModalStyle from './ModalStyles'
import { useState } from "react"
import Select from "react-select"
import { FormGroup } from "reactstrap"
import ReactSelectStyling from 'components/common/ReactSelectStyling'
import styled from 'styled-components'
import { ReactComponent as GoBackIcon } from "../images/modalBack.svg"



export default function MoreActions({ moreAction, setMoreAction }: any) {
    const [state, setState] = useState({
        action: ""
    })


    return <>
        <Modal isOpen={moreAction} toggle={setMoreAction} size='md'>
            {moreAction &&
                <ModalStyle>
                    <div className="d-flex align-items-center border-bottom mb-3 pb-4 pt-2">
                        <h6 className="bold-700 mb-0 d-flex align-items-center">
                            {state.action !== "" && <GoBackIcon onClick={() => setState(prev => ({ ...prev, action: "" }))} className='mr-2 cursor-pointer' />}
                            {state.action === ""
                                ? "More Actions"
                                : state.action + " User"
                            }
                        </h6>
                        <button onClick={setMoreAction} className="ml-auto close-btn">
                            <small>Close</small><FaTimesCircle />
                        </button>
                    </div>

                    {state.action === "" && <>
                        <MoreActionsBtn
                            onClick={() => setState(prev => ({ ...prev, action: "Freeze" }))}>
                            Freeze User
                        </MoreActionsBtn>

                        <MoreActionsBtn
                            onClick={() => setState(prev => ({ ...prev, action: "Deactivate" }))}
                            className='my-3 deactivate'>
                            Deactivate User
                        </MoreActionsBtn>

                        <MoreActionsBtn
                            onClick={() => setState(prev => ({ ...prev, action: "Block" }))}
                            className='block'>
                            Block User
                        </MoreActionsBtn>
                    </>}

                    {state.action === "Freeze" &&
                        <>
                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Employee’s Name</label>
                                <input className='ct-input' placeholder='Enter Name' />
                            </FormGroup>

                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Employee’s Email Address</label>
                                <input className='ct-input' placeholder='Enter Email Address' />
                            </FormGroup>

                            <FormGroup>
                                <label htmlFor="">Employee’s Role</label>
                                <Select styles={ReactSelectStyling()} />
                            </FormGroup>

                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Reason for Freeze</label>
                                <input className='ct-input' placeholder='Enter Email Address' />
                            </FormGroup>
                        </>}

                    {state.action === "Block" &&
                        <>
                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Employee’s Name</label>
                                <input className='ct-input' placeholder='Enter Name' />
                            </FormGroup>

                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Employee’s Email Address</label>
                                <input className='ct-input' placeholder='Enter Email Address' />
                            </FormGroup>

                            <FormGroup>
                                <label htmlFor="">Employee’s Role</label>
                                <Select styles={ReactSelectStyling()} />
                            </FormGroup>

                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Reason for Blocking</label>
                                <input className='ct-input' placeholder='Enter Email Address' />
                            </FormGroup>
                        </>}

                    {state.action === "Deactivate" &&
                        <>
                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Employee’s Name</label>
                                <input className='ct-input' placeholder='Enter Name' />
                            </FormGroup>

                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Employee’s Email Address</label>
                                <input className='ct-input' placeholder='Enter Email Address' />
                            </FormGroup>

                            <FormGroup>
                                <label htmlFor="">Employee’s Role</label>
                                <Select styles={ReactSelectStyling()} />
                            </FormGroup>

                            <FormGroup className='mt-4 d-flex flex-column'>
                                <label htmlFor="">Reason for Deactivation</label>
                                <input className='ct-input' placeholder='Enter Email Address' />
                            </FormGroup>
                        </>}

                    {state.action &&
                        <div className="d-flex align-items-center mt-4">
                            <button onClick={() => { }} className="next-btn w-100">
                                {state.action} User
                            </button>
                        </div>}
                </ModalStyle>}
        </Modal>
    </>
}

const MoreActionsBtn = styled.button`
    all: unset;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #575C76;
    border: 1px solid #E4E7F8;
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 10px;

    &.deactivate{
        color: #D88B07;
    }

    &.block{
        color: #F75E5E;
    }
`