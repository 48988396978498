import { Avatar, Checkbox } from "antd";
import DashboardTableStyle from "assets/scss/components/DashboardTableStyle";

export default function Permissions({ setState }: any) {
    return <DashboardTableStyle>
        <div className="table-responsive">
            <table className="w-100 h-100">
                <thead>
                    <tr>
                        <th>Actions</th>
                        <th>Member</th>
                        <th>Manager</th>
                        <th>Admin</th>
                    </tr>
                </thead>
                <tbody>
                    {[1, 2, 3, 4, 2].map((val, idx) =>
                        <tr key={idx}>
                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                            <td>
                                <Checkbox />
                            </td>
                            <td>
                                <Checkbox />
                            </td>
                            <td>
                                <Checkbox />
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </div>
    </DashboardTableStyle>
}