import { Avatar, Badge, Col, Divider, Dropdown, MenuProps, Row, Timeline } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import styled from "styled-components"

export default function ViewMore() {


    const items: MenuProps['items'] = [
        {
            label: 'APPROVED',
            key: 'APPROVED',
        },
        {
            label: 'DECLINED',
            key: 'DECLINED',
        },
    ];


    return <>
        <Row gutter={[30, 30]}>
            <Col xs={24} md={12}>
                <Style className="p-3">
                    <p>Name: <span>Emmanuel Akpan</span></p>
                    <p>Email: <span>emmanuelakpan@mail.com</span></p>

                    <Divider type="horizontal" />

                    <p>Activity: <span>Made new payment for - NURTW</span></p>
                    <p>Date: <span>Feb 27, 2023</span></p>
                    <p>Time: <span>1 day ago</span></p>
                </Style>
            </Col>
            <Col xs={24} md={12}>
                <Timeline>
                    {[1, 2, 3, 4, 5, 6].map((user: any, idx: number) => {
                        return <Timeline.Item
                            key={idx}
                            dot={
                                <Badge
                                    style={{ display: "success" }}
                                    color="#12B76A" size={"default"} offset={[-3, 25]} dot>
                                    <Avatar style={{ background: "#BD814A" }}>EA</Avatar>
                                </Badge>
                            }
                        >
                            <AvatarDetails>
                                <p className="name">James FarEmmanuel Akpan</p>
                                <p className="info">Made new payment for <span className="sub-info">- NURTW</span></p>
                            </AvatarDetails>
                        </Timeline.Item>
                    })}
                </Timeline>
            </Col>
        </Row>
    </>
}

const AvatarDetails = styled.div`
    margin-left: 6px;
    padding-bottom: 15px;
    p {
        margin: 0;
        font-size: 12px;

        &.name {
            font-weight: 600;

            span {
                font-weight: 300;
                font-size: 10px;
                margin-left: 5px;
            }
        }

        &.info {
            font-weight: 300;

            span {
                font-weight: 400;
                margin-left: 5px;
                color: #DF9E30;
                font-style: italic;
            }
        }
    }
`
const Style = styled.div`
    background: #EFF1FB;
    border: 1px solid #E4E7F8;
    border-radius: 4px;

    p{
        font-weight: 400;
        font-size: 14px;
        color: #3A3D4E;

        span{
            font-weight: 600;
        }
    }
`
