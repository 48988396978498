import Styles from "../styles/Styles";
import { ReactComponent as SuccessIcon } from "../images/SuccessIcon.svg";
import ResendVerification from "../ResendVerification";

export default function Login({ message }: any) {
  return <>
    <Styles>
      <div style={{ width: "fit-content" }} className="card-section success">
        <SuccessIcon className="mx-auto" />
        <h6 className="mx-auto mt-3">Account successfully created </h6>
        <p className="col-12 col-md-9 mb-4 mx-auto">
          {message}
        </p>
        <div className="px-3">
          <ResendVerification />
        </div>
      </div>
    </Styles >
  </>
}