import styled from "styled-components";
import { useState } from "react";
import Categories from "./Categories";
import SupportActivities from "./SupportActivities";
import Troubleshoot from "./Troubleshoot";
import Feedback from "./Feedback";

const ChatSupport = () => {
    const [display, setDisplay] = useState(1);

   

    return ( 
        <Con>
            <header>
                <h2>Here you can find helpful articles and guides to assist you in solving your issues.</h2>
                <div className="search-box">
                    <img src="/images/dashboard/search.svg" alt="img" />
                    <input type="search" placeholder="Search..." />
                </div>
            </header>

            <main className="help-box">
                {display==1 && <Categories setDisplay={setDisplay} />}
                {display==2 && <SupportActivities setDisplay={setDisplay} />}
                {display==3 && <Troubleshoot setDisplay={setDisplay} />}
                {display==4 && <Feedback setDisplay={setDisplay} />}
            </main>
        </Con>
     );
}

const Con = styled.div`
    padding: 37px;
    width: 91vw;

    header {
        width: 1170px;
        height: 246px;
        background: linear-gradient(180deg, #768CFF 0%, #7DB1FF 100%);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        h2 {
            width: 605px;
            height: 66px;
            font-weight: 700;
            font-size: 24px;
            color: #EFF1FB;
            margin-left: 250px;
        }

        .search-box {
            width: 580px;
            height: 54px;
            background: #F3F4F5;
            border-radius: 4px;
            margin-left: 260px;
            display: flex;
            align-items: center;
            padding: 19px;
            gap: 12px;

            input {
                width: 450px;
                outline: none;
                border: none;
                margin-top: 5px;
                background: transparent;
                display: flex;
                align-items: center;
            }
        }
    }
`
 
export default ChatSupport;