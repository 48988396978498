import React from 'react'
import styled from "styled-components";
import { useNavigate } from 'react-router-dom'
import type { MenuProps } from 'antd';

import { Avatar, Divider, Dropdown, Menu, Tabs } from "antd";

const NewNav = () => {
    const navigate = useNavigate()

    const items: MenuProps['items'] = [
        {
            label: <a href="https://www.antgroup.com">Update Profile</a>,
            key: '0',
        },
        {
            label: <a href="https://www.aliyun.com">Change Password</a>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: 'Logout',
            key: '3',
        },
    ];

    const menu = (
        <Menu>
            <Menu.Item>Update Profile</Menu.Item>
            <Menu.Item>Change Password</Menu.Item>
            <Menu.Item >Logout</Menu.Item>
        </Menu>
    );

    return (
        <Con>
            <HeaderRow>
                <div className='left'>
                    <img src="/images/dashboard/dashLogo.svg" alt="img" />
                </div>
                <div className='right'>
                    <span className='first'><img src="/images/dashboard/settings.svg" alt="img" /></span>
                    <span><img src="/images/dashboard/notf.svg" alt="img" /></span>
                    <Divider style={{ height: 30, background: "#CECECE" }} className="mx-3" type="vertical" />
                    <Dropdown trigger={["click"]} menu={{ items }}>
                        <div>
                            <Avatar src={"https://xsgames.co/randomusers/avatar.php?g=male"} size={35} />
                            <span className='small'>Yomi Adebiyi</span>
                            <span><img src="/images/dashboard/drop.svg" alt="img" /></span>
                        </div>
                    </Dropdown>

                </div>
            </HeaderRow>
            <TabRowCon >
                <Tabs
                    onChange={(key) => {
                        switch (key) {
                            case "1":
                                navigate("/dashboard")
                                break;
                            case "2":
                                navigate("/user-management")
                                break;
                            case "3":
                                navigate("/dashboard/platform")
                                break;
                            case "4":
                                navigate("/dashboard/chat-support")
                                break;
                            case "5":
                                navigate("/dashboard/chat-messaging")
                                break;
                            case "6":
                                navigate("/activity-log")
                                break;
                            case "7":
                                navigate("/transactions")
                                break;
                            case "8":
                                navigate("/settlements")
                                break;
                            case "9":
                                navigate("/reports")
                                break;
                            case "10":
                                navigate("/approvals")
                                break;
                            default:
                                break;
                        }
                    }}
                    defaultActiveKey="1"
                >
                    <Tabs.TabPane tab="Dashboard" key="1" />
                    <Tabs.TabPane tab="User Management" key="2" />
                    <Tabs.TabPane tab="Platform Management" key="3" />
                    <Tabs.TabPane tab="Chat Support" key="4" />
                    <Tabs.TabPane tab="Chat Messaging" key="5" />
                    <Tabs.TabPane tab="Activity Log" key="6" />
                    <Tabs.TabPane tab="Transactions" key="7" />
                    <Tabs.TabPane tab="Payment Settlement" key="8" />
                    <Tabs.TabPane tab="Reports" key="9" />
                    <Tabs.TabPane tab="Approvals" key="10" />
                </Tabs>
            </TabRowCon>
        </Con>
    )
}

const Con = styled.div`
    width: 100%;   
    background: #FFFFFF;
    padding:0 100px; 
    @media (max-width: 700px) { 
        padding:0 20px; 
    }
    p{
        margin:0;
    }
`;

const HeaderRow = styled.header`
    width: 100%; 
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:20px 0;
    .left{
        img{
            height:30.44px
            
        }
        @media (max-width: 700px) { 
            margin-bottom:20px;  
        }
    }
    .right{
        display:flex;
        align-items:center;
        .first{
            margin-right:5px;
        }
        .small{
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px; 
            color: #343434;
            margin-left:10px;
        }
        div{
            cursor:pointer;
        }
    }
    .ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav{
        margin-bottom: 0 !important;
    }
    @media (max-width: 700px) { 
        flex-direction:column; 
        align-items:flex-start;
    }

`

const TabRowCon = styled.div`
    width: 100%; 
    .ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav{
        margin-bottom: 0 !important;
    }

`

export default NewNav