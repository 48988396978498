import React from 'react'
import styled from "styled-components";


const Citizen = () => {
  return (
    <Con>
      <SelRow>
        <div className='left'>
          <h3>Transactions</h3>
        </div>
        <div className='right'>
          <DropBox>
            <p>Filter by</p>
            <img src="/images/dashboard/fullDrop.svg" alt="img" />
          </DropBox>
        </div>
      </SelRow>
      <MyTable>
        <div>Transaction history</div>
        <tr>
          <th>TRANSACTION ID</th>
          <th>PAYMENT BY:</th>
          <th>Received by:</th>
          <th>BILL</th>
          <th>MODE</th>
          <th>DATE</th>
          <th></th>
        </tr>
        <tr>
          <td>sunnyphilip51@gmail.com</td>
          <td>Philip</td>
          <td>Bassey</td>
          <td>Ichigo</td>
          <td>Oshodi Isolo</td>
          <td>Male</td> 
          <td><More>View </More></td>
        </tr>
        <tr>
          <td>sunnyphilip51@gmail.com</td>
          <td>Philip</td>
          <td>Bassey</td>
          <td>Ichigo</td>
          <td>Oshodi Isolo</td>
          <td>Male</td> 
          <td><More>View </More></td>
        </tr>
      </MyTable>
    </Con>
  )
}

const Con = styled.div`
  width: 100%;  
`

const DropBox = styled.div`
    width: 143px;
    height: 37px; 
    background: #EFF1FB; 
    border: 1px solid #C8CFF2;
    border-radius: 2px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px; 
    color: #343434;
    padding:10px;
`;

const SelRow = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:20px;
    .left{
      h3{
        font-family: 'Anek Telugu';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px; 
        color: #242424;
        margin-bottom:0;
      }  
    }

`;

const MyTable = styled.table`
    width: 100%; 
    border: 1px solid #E4E7F8;
    border-radius: 4px;
    background: #FFFFFF;
    div{
      width:100%;
      height: 63px; 
      background: #FFFFFF;
      display:flex; 
      padding:20px;
      align-items:center;
      p{
        margin:0;
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px; 
        color: #5C5C5C;

      }
    }
    tr{
        border-bottom: 1px solid #E4E7F8; 
        th{
            text-align:left;
            padding:10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px; 
            color: #575C76;
            background: #F9FAFB;; 
        }
        td{
            padding:12px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px; 
            color: #575C76; 
            vertical-align: middle;
        }
        .last{
            display:flex;
            img{
                margin-left:10px;
            }
        }
    }
`;

const More = styled.button` 
  padding:7px 21px 7px 21px;
  height: 40px; 
  background: rgba(255, 255, 255, 0.17); 
  border: 1px solid #E4E7F8;
  border-radius: 4px; 
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; 
  color: #23252F; 
  img{
    margin-left:5px;
  }
`;

export default Citizen