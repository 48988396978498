import styled from "styled-components";
import user from '../../../assets/platUser.svg';
import tick from '../../../assets/tick.svg'


const Chatbox = ({ setView }: any) => {

    const chat = [
        {
            img: user,
            name:"Francis Ayum",
            message:"Happy birthday to you man!!",
            time: "08:30AM",
            status: tick,
        },
        {
            img: user,
            name:"Francis Ayum",
            message:"Happy birthday to you man!!",
            time: "08:30AM",
            status: tick,
        }
    ]

    const employee = [
        {
            img: user,
            name:"Francis Ayum",
        },
        {
            img: user,
            name:"Francis Ayum",
        },
        {
            img: user,
            name:"Francis Ayum",
        }
    ]

    const replychat = [
        {
            img: user,
            msg: "The new design systems was uploaded by the product design team about 2 hours ago.",
            msg2: "Have you seen it?",
            time: "08:30AM"
        }
    ]

    const sendchat = [
        {
            img: user,
            msg: "Oh really?!",
            msg2: "That’s good. I’ll check right away",
            msg3: "Thanks a lot man!",
            time: "08:30AM"
        }
    ]



    return ( 
        <Con>
               <div className="left">
                <h3>Chats</h3>

                <div className="filter">
                    <p>Filter:</p>
                    <div className="box">
                        <p>Newest</p>
                        <img src="/images/dashboard/arrown-down.svg" alt="img" />
                    </div>
                </div>

                <div className="search-box">
                    <img src="/images/dashboard/search.svg" alt="img" />
                    <input type="search" placeholder="Search or start a new chat..." />
                </div>

                <div className="chat">
                    <h3>Chat</h3>

                    {chat.map(({name, status, img, time, message})=> (
                        <div>
                            <div className="user" onClick={() => setView(2)}>
                                <div className="user-info">
                                    <img src={img} alt="img" />
                                    <div className="text">
                                        <p id="name">{name}</p>
                                        <p id="msg">{message}</p>
                                    </div>
                                </div>

                                <div className="status">
                                    <p id="time">{time}</p>
                                    <img src={tick} alt="img" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="empl">
                    <h3>Employee</h3>

                    {employee.map(({name, img})=> (
                        <div>
                            <div className="user">
                               <img src={img} alt="img" />
                               <p>{name}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="right">
               <div className="top">
                    <h4>Francis Ayum</h4>
                    <p>Online</p>
               </div>

               <div className="chat">
                        <div className="reply">
                            {replychat.map(({img, msg, msg2, time}) => 
                                <div id="reply">
                                    <div className="top-msg">
                                        <img src={user} alt="img" />
                                        <p>{msg}</p>
                                        <small>{time}</small>
                                    </div>

                                    <div className="bottom-msg">
                                        <p>{msg2}</p>
                                        <small>{time}</small>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="send">
                            {sendchat.map(({img, msg, msg2, msg3, time}) => 
                                <div id="send">
                                    <div className="top-msg">
                                        <small>{time}</small>
                                        <p>{msg}</p>
                                        <img src={user} alt="img" />
                                    </div>

                                    <div className="mid-msg">
                                        <small>{time}</small>
                                        <p>{msg2}</p>
                                    </div>

                                    <div className="bottom-msg">
                                        <small>{time}</small>
                                        <p>{msg3}</p>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="message">
                            <input type="text" placeholder="Enter message..." />
                            <img src="/images/dashboard/smile.svg" alt="img" />
                            <img src="/images/dashboard/attach.svg" alt="img" />
                            <img src="/images/dashboard/sendbtn.svg" alt="img" />
                        </div>
               </div>
            </div>
        </Con>
     );
}

const Con = styled.div`
        height: 705px;
        background: #FFFFFF;
        border: 1px solid #E4E7F8;
        border-radius: 4px;
        margin-top: 24px;
        display: flex;

        .left {
            width: 350px;
            border-right: 1px solid #E4E7F8;
            padding: 24px;

            .filter {
                display: flex;
                align-items: center;
                gap: 10px;
        
                .box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;          
                    width: 250px;
                    height: 40px;
                    border: 1px solid #EEECF6;
                    border-radius: 4px;
                }
            }

            .search-box {
                padding: 10px 14px;
                gap: 8px;
                display: flex;
                width: 300px;
                height: 48px;
                background: #FFFFFF;
                border: 1px solid #E4E7F8;
                border-radius: 4px;
                margin-top: 20px;
    
                input {
                    width: 250px;
                    outline: none;
                    border: none;
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                }
            }

            .chat {
                margin-top: 24px;

                h3{
                    font-weight: 400;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #434D64;
                }

                .user {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 16px;
                    gap: 10px;
                    width: 300px;
                    height: 73px;
                    background: rgba(243, 244, 245, 0.5);
                    border: 1px solid #F2D8AC;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    

                    .user-info {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        img {
                            width: 40px;
                            height: 40px;
                        }

                        .text {
                            #name {
                                font-weight: 600;
                                font-size: 14px;
                                color: #3A3D4E;
                            }
                            #msg {
                                font-weight: 400;
                                font-size: 12px;
                                color: #727A8B;
                            }
                        }
                    }

                    .status {
                        text-align: right;

                        p {
                            font-weight: 400;
                            font-size: 10px;
                            text-align: right;
                            color: #727A8B;
                        }

                        img {
                            margin-top: 10px;
                        }


                    }
                }
            }

            .empl {
                margin-top: 24px;

                h3{
                    font-weight: 400;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #434D64;
                }

                .user {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 16px;
                    gap: 10px;
                    width: 300px;
                    height: 73px;
                    background: rgba(243, 244, 245, 0.5);
                    border: 1px solid #F2D8AC;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    cursor: pointer;

                    p {
                        font-weight: 600;
                        font-size: 14px;
                        color: #3A3D4E;
                    }
                    
                }
            }
        }

        .right {
            padding: 30px;

            .top {
                border-bottom: 1px solid #E4E7F8;
                width: 750px;
                padding-bottom: 15px;
            }

            .chat {
                padding-top: 130px;
                width: 750px;

               .reply {
                   text-align: left;

                   #reply {
                      .top-msg {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        p {
                            padding: 16px 10px 16px 12px;
                            width: 400px;
                            background: #F3F4F5;
                            border-radius: 0px 8px 8px 8px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #3A3D4E;
                        }

                        small {
                            font-weight: 400;
                            font-size: 10px;
                            color: #727A8B;
                        }
                      }

                      .bottom-msg {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-top: 16px;
                        margin-left: 50px;

                        p {
                            padding: 16px 10px 16px 12px;
                            width: 400px;
                            background: #F3F4F5;
                            border-radius: 0px 8px 8px 8px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #3A3D4E;
                        }

                        small {
                            font-weight: 400;
                            font-size: 10px;
                            color: #727A8B;
                        }
                      }
                   }

               }

               .send {
                    text-align: left;
                    margin-top: 35px;
                    margin-left: 330px;

                    #send {
                        .top-msg {
                          display: flex;
                          align-items: center;
                          gap: 10px;
  
                          p {
                              padding: 16px 10px 16px 12px;
                              width: 400px;
                              background: #F3F4F5;
                              border-radius: 0px 8px 8px 8px;
                              font-weight: 400;
                              font-size: 14px;
                              color: #3A3D4E;
                          }
  
                          small {
                              font-weight: 400;
                              font-size: 10px;
                              color: #727A8B;
                          }
                        }

                        .mid-msg {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            margin-top: 16px;
    
                            p {
                                padding: 16px 10px 16px 12px;
                                width: 400px;
                                background: #F3F4F5;
                                border-radius: 0px 8px 8px 8px;
                                font-weight: 400;
                                font-size: 14px;
                                color: #3A3D4E;
                            }
    
                            small {
                                font-weight: 400;
                                font-size: 10px;
                                color: #727A8B;
                            }
                          }
  
                        .bottom-msg {
                          display: flex;
                          align-items: center;
                          gap: 10px;
                          margin-top: 16px;
  
                          p {
                              padding: 16px 10px 16px 12px;
                              width: 400px;
                              background: #F3F4F5;
                              border-radius: 0px 8px 8px 8px;
                              font-weight: 400;
                              font-size: 14px;
                              color: #3A3D4E;
                          }
  
                          small {
                              font-weight: 400;
                              font-size: 10px;
                              color: #727A8B;
                          }
                        }
                     }
  
                 }

                 .message {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 16px;
                    gap: 24px;
                    width: 750px;
                    height: 56px;
                    background: #FFFFFF;
                    border: 1px solid #D0D3D8;
                    border-radius: 4px;
                    margin-top: 50px;

                        input {
                            width: 650px;
                            height: 19px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #727A8B;
                            outline: none;
                            border: none;
                        }
                    }
  
               }
            }
        }

    
`
 
export default Chatbox;