import Styles from "../styles/Styles";
import { Input, Select } from "antd";
import { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import * as yup from "yup"
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from 'formik'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ResendVerification from "../ResendVerification";


function getUserInfo(userType: string, userObject: any) {
    const requiredFields = ["username", "userType", "password", "confirmPassword"];

    if (userType === "INDIVIDUAL") {
        requiredFields.push("firstName", "middleName", "surname");
        delete userObject.businessName;
        delete userObject.businessType;
        delete userObject.businessDescription;
        delete userObject.businessContactPersonAddRequests;
    } else if (userType === "BUSINESS") {
        requiredFields.push(
            "businessName",
            "businessType",
            "businessDescription",
            "businessContactPersonAddRequests"
        );
        delete userObject.firstName;
        delete userObject.middleName;
        delete userObject.surname;
    } else {
        throw new Error("Invalid user type!");
    }

    const userInfo = userObject || {};
    for (let field of requiredFields) {
        if (!userInfo.hasOwnProperty(field)) {
            userInfo[field] = "";
        }
    }

    return userInfo;
}


interface FormData {
    userType?: string,
    username: string,
    userId: string,
    surname?: string,
    firstName?: string,
    middleName?: string,
    password?: string,
    confirmPassword?: string,
    businessName?: string,
    businessType?: string,
    businessDescription?: string,
    businessContactPersonAddRequests: [
        {
            name?: string,
            email?: string,
            mobileNumber?: string
        }
    ]
}


const validationSchema = yup.object().shape({
    username: yup.string().required("Username cannot be blank"),
    userId: yup.string().required("User ID cannot be blank"),
    surname: yup.string()
        .when("userType", {
            is: (val: any) => val == "BUSINESS",
            then: yup.string().nullable(),
            otherwise: yup.string().nullable().required("Surname cannot be blank")
        }),
    firstName: yup.string()
        .when("userType", {
            is: (val: any) => val == "BUSINESS",
            then: yup.string().nullable(),
            otherwise: yup.string().nullable().required("First Name cannot be blank")
        }),
    middleName: yup.string().nullable(),
    password: yup.string().nullable().required('Required').min(5, 'Invalid Password'),
    confirmPassword: yup.string().nullable()
        .required('Required')
        .oneOf([yup.ref('password'), null], 'Passwords do not match'),
    businessName: yup.string()
        .when("userType", {
            is: (val: any) => val == "BUSINESS",
            then: yup.string().nullable().required("Business Name cannot be blank"),
            otherwise: yup.string().nullable(),
        }),
    businessType: yup.string()
        .when("userType", {
            is: (val: any) => val == "BUSINESS",
            then: yup.string().nullable().required("Business Type cannot be blank"),
            otherwise: yup.string().nullable(),
        }),
    businessDescription: yup.string()
        .when("userType", {
            is: (val: any) => val == "BUSINESS",
            then: yup.string().nullable().required("Business Description cannot be blank"),
            otherwise: yup.string().nullable(),
        }),
    businessContactPersonAddRequests: yup.array()
        .when("userType", {
            is: (val: any) => val == "BUSINESS",
            then: yup.array().of(yup.object({
                name: yup.string().nullable().required("Name cannot be blank"),
                email: yup.string().nullable().required("Email cannot be blank").email("Email is not valid"),
                mobileNumber: yup.number().nullable().required("Number cannot be blank"),
            })),
            otherwise: yup.array().notRequired(),
        }),
})


export default function UpdateProfile() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get("token")

    const [businessTypes, setBusinessTypes] = useState({
        loading: false,
        data: []
    })
    const getEnums = async () => {
        setBusinessTypes((prev) => ({ ...prev, loading: true }))
        try {
            // const { data: { data: { BusinessType } } } = await ApiNoAuth.get("/enums")
            // setBusinessTypes((prev) => ({ ...prev, data: BusinessType }))
        } catch (error) {
        } finally {
            setBusinessTypes((prev) => ({ ...prev, loading: false }))
        }
    }

    useEffect(() => {
        getEnums()
    }, [])

    const [verifyingEmail, setVerifyingEmail] = useState(false)
    const [initialValues, setInitialValues] = useState<FormData>({
        username: "",
        surname: "",
        userId: "",
        firstName: "",
        middleName: "",
        password: "",
        confirmPassword: "",
        businessName: "",
        businessType: "",
        businessDescription: "",
        businessContactPersonAddRequests: [
            {
                name: "",
                email: "",
                mobileNumber: ""
            }
        ]
    })


    const [errorOccured, setErrorOccured] = useState(false)
    const verifyEmail = async () => {
        setVerifyingEmail(true)
        const toastId = toast.loading("Verifying email...")
        try {
            // const { data } = await ApiNoAuth.post(`/user/verify-email`, { token: token })
            // if (data.statusCode === "15") {
            //     toast.update(toastId,
            //         {
            //             render: data?.statusMessage,
            //             type: "info",
            //             isLoading: false,
            //             autoClose: 10000
            //         });
            //     return navigate("/")
            // }
            // setInitialValues(() => ({ ...data?.data }))
            // formik.setFieldValue("userType", data?.data?.userType)
            // formik.setFieldValue("userId", data?.data?.userId)
            // formik.setFieldValue("username", data?.data?.email || data?.data?.mobileNumber)
            // formik.setFieldValue("businessContactPersonAddRequests", data?.data?.businessContactPersons)
            // toast.update(toastId,
            //     {
            //         render: data?.statusMessage || "Successfully verified Email!",
            //         type: data.statusCode === "14" ? "info" : "success",
            //         isLoading: false,
            //         autoClose: 10000
            //     });
        } catch (error: any) {
            setErrorOccured(true)
            toast.update(toastId, { render: error?.response?.data?.statusMessage || 'An error occured', type: "error", isLoading: false, autoClose: 5000 });
        } finally {
            setVerifyingEmail(false)
        }
    }

    useEffect(() => {
        verifyEmail()
    }, [])

    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (fieldData: any, helpers) => {
            try {
                const payload = getUserInfo(fieldData.userType, fieldData)
                // await ApiNoAuth.put(`/user/update-user-profile`, payload)
                toast.success("Profile Updated")
                navigate("/")
            } catch (error: any) {
                toast.error(
                    error?.response?.data?.statusMessage || 'An error occured'
                )
            }
        },
        validationSchema
    })


    return <Styles>
        {!verifyingEmail && <div className="card-section">
            {errorOccured ?
                <ResendVerification /> :
                <>
                    <h3>Complete Registration</h3>
                    <FormikProvider value={formik}>

                        <Input
                            placeholder="Username"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled
                            size="large" className="mt-3 input-field" />
                        <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='username' />

                        {initialValues?.userType === "BUSINESS" && <>
                            <Input
                                placeholder="Business Name"
                                name="businessName"
                                value={formik.values.businessName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                size="large" className="mt-3 input-field" />
                            <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='businessName' />

                            <Select
                                size="large"
                                loading={businessTypes.loading}
                                placeholder="Select Business Type"
                                onChange={(val) => formik.setFieldValue("businessType", val)}
                                className="input-field w-100 mb-0">
                                {businessTypes.data.map((type: string, idx) =>
                                    <Select.Option key={idx} value={type}>
                                        {type.split("_").join(" ")}
                                    </Select.Option>)}
                            </Select>
                            <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='businessType' />


                            <Input
                                placeholder="Business Description"
                                name="businessDescription"
                                value={formik.values.businessDescription}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="textarea"
                                size="large" className="mt-3 input-field" />
                            <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='businessDescription' />

                            <FieldArray validateOnChange={true} name={`businessContactPersonAddRequests`}>
                                {(addressFieldArrayProps) => {
                                    const { push: pushContact, remove: removeContact, form, insert } = addressFieldArrayProps
                                    return formik.values.businessContactPersonAddRequests?.map(({ email, name, mobileNumber }: any, idx: number) =>
                                        <div key={idx} className='col-md-12 p-0 mt-3'>
                                            <label className='textInactiveColor'>
                                                Business Contact Person
                                            </label>
                                            <Input
                                                type='text'
                                                size={"large"}
                                                id={`businessContactPersonAddRequests.${idx}`}
                                                name={`businessContactPersonAddRequests.${idx}.name`}
                                                className='input-field'
                                                placeholder='Name'
                                                value={name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Input
                                                type='text'
                                                size={"large"}
                                                id={`businessContactPersonAddRequests.${idx}`}
                                                name={`businessContactPersonAddRequests.${idx}.email`}
                                                className='input-field mt-2'
                                                placeholder='Email'
                                                value={email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Input
                                                type='text'
                                                size={"large"}
                                                id={`businessContactPersonAddRequests.${idx}`}
                                                name={`businessContactPersonAddRequests.${idx}.mobileNumber`}
                                                className='input-field mt-2'
                                                placeholder='Mobile Number'
                                                value={mobileNumber}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="d-flex">
                                                {<ErrorMessage render={(msg) => <p className='errorMsg mb-0'>{msg}</p>} name={`businessContactPersonAddRequests.${idx}.name}`} />}
                                                <div className="ml-auto d-flex">
                                                    {!formik.errors.businessContactPersonAddRequests && <p onClick={() => pushContact("")} style={{ color: "#5141a4" }} className='errorMsg mb-0 cursor-pointer'>Add</p>}
                                                    {idx !== 0 && <p onClick={() => removeContact(idx)} className='errorMsg mb-0 ml-2 cursor-pointer'>Remove</p>}
                                                </div>
                                            </div>
                                        </div>)
                                }}
                            </FieldArray>
                        </>}

                        {initialValues?.userType === "INDIVIDUAL" && <>
                            <Input
                                placeholder="Surname"
                                name="surname"
                                value={formik.values.surname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                size="large" className="mt-3 input-field" />
                            <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='surname' />

                            <Input
                                placeholder="First Name"
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                size="large" className="mt-3 input-field" />
                            <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='firstName' />

                            <Input
                                placeholder="Middle Name"
                                name="middleName"
                                value={formik.values.middleName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                size="large" className="mt-3 input-field" />
                            <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='middleName' />
                        </>}


                        <Input.Password
                            placeholder="Password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            size="large" className="mt-3 input-field" />
                        <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='password' />


                        <Input.Password
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            size="large" className="mt-3 input-field" />
                        <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='confirmPassword' />

                        <button
                            disabled={!formik.isValid || formik.isSubmitting}
                            type="button"
                            onClick={() => formik.submitForm()}
                            className="ct-button mt-4 w-100">
                            {formik.isSubmitting ? <span className='spinner-border spinner-border-sm text-white'></span> : "Continue"}
                        </button>
                        <div className="d-flex justify-content-center mt-4">
                            <a href="/" className="login">Login <FaLongArrowAltRight className="ml-2" /></a>
                        </div>
                    </FormikProvider>
                </>
            }
        </div>}
    </Styles >
}