import styled from "styled-components";
import { useState } from "react";
import ChatModal from "./Chat";
import SupportActivities from "./SupportActivities";


const Categories = ({display, setDisplay}: any) => {

    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true); 
    };

    const catArrays = [
        {
            header: 'Categories',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',

        }
    ]

    const featureArrays = [
        {
            header: 'Featured Articles',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.'
        }
    ]

    return ( 
        <Con>
            <div>
                {catArrays.map(({header, content})=> (
                      <div className="help-section">
                        <p id="header" onClick={() => setDisplay(2)}>{header}</p>

                        <div className="help-category">
                            <div id="help-box">
                                <h3>FAQs</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>User Guides</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box" onClick={() => setDisplay(2)} >
                                <h3>Support Articles</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>Troubleshooting Guide</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>Blogs</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>User Guide</h3>
                                <p>{content}</p>
                            </div>
                        </div>

                        </div>
                    ))}
            </div>

            <div>
                {featureArrays.map(({header, content})=> (
                      <div className="help-section">
                        <p id="header">{header}</p>

                        <div className="help-category">
                            <div id="help-box">
                                <h3>How to set up your account</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>How to manage your subscription</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>How to troubleshoot common issues</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>How to contact customer support</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>How to troubleshoot common issues</h3>
                                <p>{content}</p>
                            </div>
                            <div id="help-box">
                                <h3>How to set up your account</h3>
                                <p>{content}</p>
                            </div>
                        </div>

                        </div>
                    ))}
            </div>

            <div className="chat" onClick={showModal}>
                <img src="/images/dashboard/Chat.svg" alt="img" />
            </div>
            <div className="ouput">
                <ChatModal visible={visible} onCancel={() => setVisible(false)} />
            </div>

            {/* {Currentpage==1 && <SupportActivities />} */}
            {/* onClick={() => setCurrentpage(1)} */}
        </Con>
     );
}

const Con = styled.div`
        display: flex;
        flex-direction: column;
        padding: 32px 22px;
        gap: 24px;
        width: 928px;
        height: 744px;
        background: #fff;
        border-radius: 8px;
        margin-left: 135px;
        margin-top: 40px;

        .help-section {
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-top: 7px;

            #header {
                font-weight: 700;
                font-size: 20px;
                line-height: 120%;
                color: #14213D;
                margin-left: 1rem;
            }

            .help-category {
                display: flex;
                flex-wrap: wrap;
                gap: 18px;
                margin-left: 1rem;

                #help-box {
                    align-items: flex-start;
                    padding: 16px;
                    gap: 10px;
                    width: 272px;
                    height: 123px;
                    border: 1px solid #EEECF6;
                    border-radius: 8px;
                    cursor: pointer;

                    h3 {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 120%;
                        color: #3A3D4E;
                    }

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 156%;
                        color: #434D64;
                    }
                }
            }
        }

        .chat {
            margin-left: 950px;
            cursor: pointer;
        }

        .output {
            position: relative;
            left: 50;
        }
`
 
export default Categories;