import { useState } from "react"
import styled from "styled-components"
import Header from "./subComponent/Header"
import Portal from "./subComponent/Portal"

export default function ActivityLog() {
    const [state, setState] = useState({
        tab: "Portal",
        addUser: false,
        moreAction: false
    })

    return <MainCon className="py-4">
        <Header state={state} setState={setState} />

        <Portal />
    </MainCon>
}

const HeaderStyle = styled.div`
    background: #FFFFFF;
    border: 1px solid #E4E7F8;
    border-radius: 4px;
`

const MainCon = styled.div`
    
`