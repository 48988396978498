import Dashboard from 'pages/NewDashboard'
import ForgotPassword from 'pages/NewAuth/ForgotPassword'
import Login from 'pages/NewAuth/Login'
import OTP from 'pages/NewAuth/OTP'
import Register from 'pages/NewAuth/Register'
import ResendVerification from 'pages/NewAuth/ResendVerification'
import ResetPassword from 'pages/NewAuth/ResetPassword'
import UpdateProfile from 'pages/NewAuth/UpdateProfile'
import Verify from 'pages/NewAuth/Verify'
import UserManagement from 'pages/UserManagement'
import ActivityLog from 'pages/ActivityLog'
import Transactions from 'pages/Transactions'
import Settlement from 'pages/Settlement'
import Approval from 'pages/Approvals'
import Report from 'pages/Report'
import Index from '../pages/dashboard/Platform'
import Govt from '../pages/dashboard/Platform/Govt'
import Citizens from '../pages/dashboard/Platform/Citizens'
import ChatSupport from '../pages/dashboard/ChatSupport/index'
import ChatMessaging from '../pages/dashboard/ChatMessaging/Index'

export interface routeInterface {
  path: string
  name: string
  header?: string
  exact?: boolean
  component?: any
  breadcrumb?: Record<string, string>[]
  children?: Array<routeInterface>
}


const authRoutes: routeInterface = {
  path: '/auth',
  name: 'Auth',
  header: 'Auth',
  children: [
    {
      path: '/',
      name: 'Login',
      header: 'Login',
      component: Login,
    },
    {
      path: '/user/verify-email',
      name: 'Verify Email',
      header: 'Verify Email',
      component: UpdateProfile,
    },
    {
      path: '/auth/resend-verification-link',
      name: 'Resend Verification Link',
      header: 'Resend Verification Link',
      component: ResendVerification,
    },
    {
      path: '/auth/register',
      name: 'Register',
      header: 'Register',
      component: Register,
    },
    {
      path: '/auth/verify',
      name: 'Verify',
      header: 'Verify',
      component: Verify,
    },
    {
      path: '/auth/forgot-password',
      name: 'Forgot Password',
      header: 'Forgot Password',
      component: ForgotPassword,
    },
    {
      path: '/auth/reset-password/:token',
      name: 'Reset Password',
      header: 'Reset Password',
      component: ResetPassword,
    },
    {
      path: '/auth/otp',
      name: 'OTP',
      header: 'OTP',
      component: OTP,
    }
  ],
}


const dashboardRoute: routeInterface = {
  path: '/dashboard',
  name: 'Dashboard',
  header: 'Dashboard',
  children: [
    {
      path: 'dashboard/platform',
      name: 'Platform',
      header: 'Platform',
      component: Index,
    },
    {
      path: 'dashboard/platform/government',
      name: 'Platform',
      header: 'Platform',
      component: Govt,
    },
    {
      path: 'dashboard/platform/citizen',
      name: 'Platform',
      header: 'Platform',
      component: Citizens,
    },
    {
      path: 'dashboard/chat-support',
      name: 'Chat Support',
      header: 'Chat Support',
      component: ChatSupport,
    },
    {
      path: 'dashboard/chat-messaging',
      name: 'Chat Message',
      header: 'Chat Message',
      component: ChatMessaging,
    },
  ],
}

const userMGTRoute: routeInterface = {
  path: '/user-management',
  name: 'User Management',
  header: 'User Management',
  component: UserManagement
}

const mainDashboardRoute: routeInterface = {
  path: '/dashboard',
  name: 'Dashboard',
  header: 'Dashboard',
  component: Dashboard
}

const activityLogRoute: routeInterface = {
  path: '/activity-log',
  name: 'Activity Log',
  header: 'Activity Log',
  component: ActivityLog
}

const transactionRoute: routeInterface = {
  path: '/transactions',
  name: 'User Management',
  header: 'User Management',
  component: Transactions
}

const settlementRoute: routeInterface = {
  path: '/settlements',
  name: 'User Management',
  header: 'User Management',
  component: Settlement
}

const approvalRoute: routeInterface = {
  path: '/approvals',
  name: 'User Management',
  header: 'User Management',
  component: Approval
}

const reportRoute: routeInterface = {
  path: '/reports',
  name: 'Report',
  header: 'Report',
  component: Report
}


export const auth: Array<routeInterface> = [authRoutes]
export const dashboard: Array<routeInterface> = [
  dashboardRoute,
  userMGTRoute,
  activityLogRoute,
  transactionRoute,
  settlementRoute,
  approvalRoute,
  reportRoute,
  mainDashboardRoute
]
