import styled from "styled-components";
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const ModalStyle = styled(ModalBody)`
    padding: 30px 30px;
    .close-btn{
        all: unset;
        cursor: pointer;
        small{
            margin-right: 10px;
        }
    }

    label{
        color: #242424;
    }

    .next-btn{
        all: unset;
        padding: 8px 25px;
        background: #DF9E30;
        border-radius: 2px;
        font-size: 13px;
        color: #FFFFFF;
        cursor: pointer;
        text-align: center;
    }

    .ct-input{
        outline: unset;
        border: 1px solid #D0D3D8;   
        font-size: 13px;
        padding: 10px;
        border-radius: 3px;
        width: 100%;
    }

`

export default ModalStyle