import React, { useState, useEffect, useCallback } from 'react'
import styled from "styled-components";
import { Radio, Tabs } from 'antd';
import { useNavigate, Outlet } from 'react-router-dom' 
import OrangeButton from '../../components/dashboard/OrangeButton';
import UserManagement from './UserManagement';
import PlatformManagement from './PlatformManagement';

const Index = () => {

    const [page, setPage] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const navigate = useNavigate()

    return (
        <MainCon>
            <FirstRow>
                <div className='top'>
                    <h1>Approvals</h1> 
                </div>
                <Tabs
                    onChange={(key) => {
                        switch (key) {
                            case "1":
                                setCurrentTab(0)
                                break;
                            case "2":
                                setCurrentTab(1)
                                break;
                            default:
                                break;
                        }
                    }}
                    defaultActiveKey="1">
                    <Tabs.TabPane tab="User Management" key="1" />
                    <Tabs.TabPane tab="Platform Management" key="2" />
                </Tabs>
            </FirstRow>
            
            {currentTab === 0 && <PlatformManagement/>}
            {currentTab === 1 && <PlatformManagement />}
        </MainCon>
    )
}

const MainCon = styled.div`
    width: 100%;  
    height:100vh;
    padding:20px; 
    background:#FAFAFA;
    p{
        margin-bottom:0;
    }
`;

const FirstRow = styled.div`
    width: 100%;   
    background: #FFFFFF; 
    border: 1px solid #E4E7F8;
    border-radius: 4px;
    padding:0 20px;
    margin-bottom:20px;
    .top{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-top:20px;
        h1{
            font-family: 'Anek Telugu';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 42px; 
            color: #0B0B0B; 
            margin-bottom:0px;
            background: #FFFFFF;
        }
    }
    .ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav{
        margin-bottom: 0 !important;
    }
`;





export default Index