import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {
  routeInterface,
  auth,
  dashboard
} from './index'
import NewDashLayout from '../layouts/NewDashLayout'
import AuthLayout from '../layouts/AuthLayout'

const childRoutes = (Layout: any, routes: Array<any>) =>
  routes.map(
    (
      {
        children,
        path,
        component: Component,
        name,
        breadcrumb,
      }: routeInterface,
      index: number
    ) =>
      children ? (
        // Route item with children
        children.map(
          (
            { path, component: Component, name, breadcrumb }: routeInterface,
            index: number
          ) => (
            <Route
              key={index}
              path={path}
              element={
                <Layout breadcrumb={breadcrumb}>
                  <Component ComponentName={name} />
                </Layout>
              }
            />
          )
        )
      ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          element={
            <Layout breadcrumb={breadcrumb}>
              <Component ComponentName={name} />
            </Layout>
          }
        />
      )
  )

const AppRoutes = () => {
  return (
    <Router>
        <Routes>
          {childRoutes(NewDashLayout, dashboard)}
          {childRoutes(AuthLayout, auth)}  
        </Routes>
    </Router>
  )
}

export default AppRoutes
