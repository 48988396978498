import styled from "styled-components";
import Block from "./Block";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';




const List = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
      
      navigate('/dashboard/platform/government');
    };

    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true); 
    };

    const tablehead = [
        {
            name: "NAME",
            status: "STATUS",
            date: "DATE CREATED",
            revenue: "REVENUE",
            user: "USERS",
            action: "ACTIONS"
        }
    ];

    const category = [
        {
            id: 1,
            name: "Alaje Government",
            status: "Active",
            date: "12/12/22",
            revenue: "$100,000",
            user: "200",
            block: "Block",
            view: "View"
        },
        {
            id: 2,
            name: "Alaje Citizens",
            status: "Active",
            date: "12/12/22",
            revenue: "$100,000",
            user: "200",
            block: "Block",
            view: "View"
        },
        {
            id: 3,
            name: "School Management",
            status: "Inactive",
            date: "12/12/22",
            revenue: "$100,000",
            user: "200",
            block: "Block",
            view: "View"
        }
    ];

    return ( 
       <Con>

            <TableArea>
                <header>
                    {tablehead.map(({name, status, date, revenue, user, action})=>(
                        <div>
                            <p>{name}</p>
                            <p id="status">{status}</p>
                            <p id="date">{date}</p>
                            <p id="rev">{revenue}</p>
                            <p id="user">{user}</p>
                            <p id="act">{action}</p>
                            <img src="/images/dashboard/actionque.svg" alt="icon" />
                        </div>
                    ))}
                </header>

                <main>
                    {category.map(({name, status, date, revenue, user, block, view})=> (
                        <div>
                            <p id="name">{name}</p>
                            <p id="status">{status}</p>
                            <p id="date">{date}</p>
                            <p id="rev">{revenue}</p>
                            <p id="user">{user}</p>
                            <p className="act" onClick={handleButtonClick}>{view}</p>
                            <p id="act" onClick={showModal}>{block}</p>
                            <Block visible={visible} onCancel={() => setVisible(false)} />
                            
                        </div>
                    ))}
                </main>
                
            </TableArea>

            
       </Con>
     );
}

const Con = styled.div`
 height: 100vh;
`

const DisplayNav = styled.div`
    padding-right: 35px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 14px;
        width: 338px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E4E7F8;
        border-radius: 4px;

            input {
                width: 280px;
                outline: none;
                border: none;
            }

    }

    .view {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 14px;
        gap: 16px;
        width: 80px;
        height: 46px;
        background: #FFFFFF;
        border: 1px solid #EEECF6;
        border-radius: 4px;
    }
    
`

const TableArea = styled.div`
    header {
        height: 44px;
        margin-top: 24px;
        color: #667085;
        font-size: 12px;
        background: #F9FAFB;
        border: none;

        div {
            display: flex;
           

            #status {
                margin-left: 179px;
            }
            #date {
                margin-left: 170px;
            }
            #rev {
                margin-left: 129px;
            }
            #user {
                margin-left: 159px;
            }
            #act {
                margin-left: 96px;
            }

            img {
                margin-left: 5px;
                margin-top: -3px;
            }
        }
    }

    main {
        div {
            display: flex;
            align-items: center;
            height: 72px;
            background: #fff;
            margin-bottom: 5px;
            width: 1159px;
            margin-top: 5px;
            border-bottom: 1px solid #EAECF0;
            padding-left: 24px;
            font-size: 14px;


            img {
                width: 42px;
                height: 42px;
            }

            #name {
                width: 132px;
                font-weight: 500;
                color: #23252F;
            }
            #status {
                margin-left: 75px;
                width: 51px;
            }
            #date {
                margin-left: 160px;
            }
            #rev {
                margin-left: 155px;
            }
            #user {
                margin-left: 149px;
            }
            #act {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 7px 21px;
                gap: 10px;
                width: 78px;
                height: 32px;
                border: 1px solid #C8CFF2;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 10px;
                color: #F53E3E;
            }
            .act {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 7px 21px;
                gap: 10px;
                width: 78px;
                height: 32px;
                border: 1px solid #C8CFF2;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 110px;
            }
        }
    }
`
 
export default List;