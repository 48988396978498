import Styles from "../styles/Styles";
import { ReactComponent as IndividualIcon } from "../images/Individual.svg"
import { ReactComponent as BusinessIcon } from "../images/Buisness.svg"
import { Divider, Input } from "antd";
import { useState } from "react";
import { ReactComponent as Mail } from "../images/Mail.svg";
import { ReactComponent as NigerFlag } from "../images/NigerFlag.svg";
import { FaLongArrowAltRight } from "react-icons/fa";
import * as yup from "yup"
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { toast } from "react-toastify";
import Success from "../Success"


interface FormData {
    userType: string,
    registrationType: string,
    email?: string,
    mobileNumber?: number | null | string
}

const initialValues: FormData = {
    userType: "",
    registrationType: "EMAIL",
    email: "",
    mobileNumber: ""
}

const validationSchema = yup.object().shape({
    userType: yup.string().required("Required"),
    registrationType: yup.string().required("Required"),
    email: yup.string().when("registrationType", {
        is: (val: any) => val == "EMAIL",
        then: yup.string().required("Email cannot be blank").email("Email is not valid"),
        otherwise: yup.string().notRequired()
    }),
    mobileNumber: yup.number().when("registrationType", {
        is: (val: any) => val == "MOBILE_NUMBER",
        then: yup.number().required("Mobile Number cannot be blank").min(11, "Mobile Number is not valid"),
        otherwise: yup.number().notRequired()
    }),
})


export default function Register() {
    const [successful, setSuccessful] = useState({
        mode: false,
        message: ""
    })

    const onSubmit = async (formData: FormData) => {
        if (formData?.email) {
            delete formData.mobileNumber
        } else {
            delete formData.email
        }
        try {
            // const { data } = await ApiNoAuth.post('/user', formData)
            // setSuccessful(() => ({ message: data?.statusMessage, mode: true }))
        } catch (error: any) {
            toast.error(error.response?.data?.statusMessage || `An error occured`)
        }
    }
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit,
        validationSchema
    })

    const [state, setState] = useState({
        stage: 1,
        mode: "email"
    })
    return <>
        {!successful.mode ? <Styles>
            <div className="card-section">
                <h3>Register as</h3>
                <FormikProvider value={formik}>
                    {state.stage === 1 && <>
                        <div onClick={() => formik.setFieldValue("userType", "INDIVIDUAL")}
                            className={`${formik.values.userType === "INDIVIDUAL" && "active"} selection-card mb-4`}>
                            <IndividualIcon />
                            <Divider type="vertical" className="divider" />
                            <div>
                                <h5>Individual</h5>
                            </div>
                            <div className="circle ml-auto"></div>
                        </div>

                        <div onClick={() => formik.setFieldValue("userType", "BUSINESS")}
                            className={`${formik.values.userType === "BUSINESS" && "active"} selection-card`}>
                            <BusinessIcon />
                            <Divider type="vertical" className="divider" />
                            <div>
                                <h5>Business</h5>
                            </div>
                            <div className="circle ml-auto"></div>
                        </div>
                        <button disabled={formik.values.userType === ""} onClick={() => setState((prev) => ({ ...prev, stage: prev.stage + 1 }))} className="ct-button mt-4 w-100">Join alajetech</button>
                    </>}

                    {state.stage === 2 && <>
                        {formik.values.registrationType === "EMAIL" && <>
                            <Input
                                placeholder="Email Address"
                                prefix={<Mail className="icon" />}
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                size="large" className="mt-3 input-field" />
                            <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='email' />
                            <p className="or-register my-3" onClick={() => formik.setFieldValue("registrationType", "MOBILE_NUMBER")}>or register with mobile number</p>
                        </>}

                        {formik.values.registrationType === "MOBILE_NUMBER" && <>
                            <Input
                                placeholder="+234"
                                prefix={<NigerFlag className="icon" />}
                                name="mobileNumber"
                                type="number"
                                value={formik.values?.mobileNumber || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                size="large" className="mt-3 input-field" />
                            <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='mobileNumber' />
                            <p className="or-register my-3" onClick={() => formik.setFieldValue("registrationType", "EMAIL")}>or register with Email</p>
                        </>}
                        <button
                            disabled={!formik.isValid || formik.isSubmitting}
                            type="button"
                            onClick={() => formik.submitForm()}
                            className="ct-button mt-4 w-100">
                            {formik.isSubmitting ? <span className='spinner-border spinner-border-sm text-white'></span> : "Continue"}
                        </button>
                        <div className="d-flex justify-content-center mt-4">
                            <a href="/" className="login">Login <FaLongArrowAltRight className="ml-2" /></a>
                        </div>
                    </>}
                </FormikProvider>
            </div>
        </Styles > : <Success message={successful.message} />}
    </>
}