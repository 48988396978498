import { Modal, Avatar, Button } from 'antd';
import { FaTimes } from "react-icons/fa";
import styled from 'styled-components';


const Modals = ({ visible, onCancel }: any) => {
    return <Modal
        title=""
        footer={null}
        closable={false}
        centered
        open={visible}
        width={415}
        bodyStyle={{ padding: 10, borderRadius: "4px", height: "320px", }}
        className="pipeline-card"
        visible={visible} 
        onCancel={onCancel} 
        onOk={onCancel}
    >
       <Box>
            <Content>
                <img src="/images/dashboard/checked.svg" alt="img" />
                <h3>Feedback sent successfully</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </Content>
           <Buttons>Done</Buttons>
       </Box>
    </Modal>
}

const Box = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;

`

const Content =styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
            img {
                width: 224px;
                height: 152px;
            }

            h3 {
                font-weight: 700;
                font-size: 16px;
                color: #434D64;
            }

            p {
                font-weight: 400;
                font-size: 12px;
                text-align: center;
                color: #727A8B;
            }
`

const Buttons = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 13px 18px;
        gap: 10px;
        width: 75px;
        height: 44px;
        background: #D88B07;
        border-radius: 2px;
        color: #fff;
`



 
export default Modals;