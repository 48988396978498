import { FaCaretDown } from "react-icons/fa"
import { Avatar, Badge, Timeline } from "antd";
import styled from "styled-components";

export default function RecentActivity() {
    return <>
        <MainStyle className="py-3 px-4">
            <h6 className="bold-700 mb-4 pb-1">Recent Activity</h6>

            <Timeline>
                <Timeline.Item dot={<Avatar src={"https://i.pravatar.cc/300"} />}>
                    <div className="avatar-details">
                        <p className="name">Demi Wikinson <span className="time">Just Now</span></p>
                        <p className="info">Created a new payment type <span className="sub-info">- NURTW</span></p>
                    </div>
                </Timeline.Item>

                <Timeline.Item dot={<Badge color="#12B76A" size={"default"} offset={[-3, 25]} dot><Avatar src={"https://i.pravatar.cc/300"} /></Badge>}>
                    <div className="avatar-details">
                        <p className="name">Demi Wikinson <span className="time">3 mins ago</span></p>
                        <p className="info">Created a new payment type <span className="sub-info">- NURTW</span></p>
                    </div>
                </Timeline.Item>

                {[1, 2].map((_, idx) =>
                    <Timeline.Item key={idx} dot={<Badge color="#12B76A" size={"default"} offset={[-3, 25]} dot><Avatar src={"https://i.pravatar.cc/300"} /></Badge>}>
                        <div className={`${idx == 1 && "pb-0"} avatar-details`}>
                            <p className="name">Demi Wikinson <span className="time">3 mins ago</span></p>
                            <p className="info">Created a new payment type <span className="sub-info">- NURTW</span></p>
                        </div>
                    </Timeline.Item>)}
            </Timeline>

            <a className="show-more">Show more</a>
        </MainStyle>
    </>
}


const MainStyle = styled.div`
    background: #FFFFFF;
    border: 1px solid #E4E7F8;
    border-radius: 4px;

    .show-more{
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #D88B07;
    }

    .ant-timeline-item{
        margin-left: 10px;
    }
    .avatar-details{
        margin-left: 6px;
        padding-bottom: 15px;

        p {
            margin: 0;
            font-size: 12px;

            &.name {
                font-weight: 600;

                span {
                    font-weight: 300;
                    font-size: 10px;
                    margin-left: 5px;
                }
            }

            &.info {
                font-weight: 300;

                span {
                    font-weight: 400;
                    margin-left: 5px;
                    color: #DF9E30;
                    font-style: italic;
                }
            }
        }
    }
`