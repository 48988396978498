import React from 'react';
import './App.css';
import './assets/scss/main.scss'
import 'antd/dist/antd.min.css'
import AppRoutes from './routes/Routes'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return <>
    <AppRoutes />
    <ToastContainer />
  </>
}

export default App;
