import { useState } from 'react'
import styled from "styled-components";
import AddUserModal from './modals/AddUserModal';
import MoreActions from './modals/MoreActions';
import AllUsers from './subComponents/AllUsers';
import Header from './subComponents/Header';
import Permissions from './subComponents/Permissions';
import UserRoles from './subComponents/UserRoles';

const UserManagement = () => {
    const [state, setState] = useState({
        tab: "All Users",
        addUser: false,
        moreAction: false
    })


    return (
        <MainCon className='py-4'>
            <AddUserModal addUser={state.addUser} setAddUser={() => setState(prev => ({ ...prev, addUser: !prev.addUser }))} />
            {state.moreAction && <MoreActions moreAction={state.moreAction} setMoreAction={() => setState(prev => ({ ...prev, moreAction: !prev.moreAction }))} />}
            <Header setState={setState} state={state} />

            {state.tab == "All Users" && <AllUsers setState={setState} />}
            {state.tab == "User Roles" && <UserRoles setState={setState} />}
            {state.tab == "Permissions" && <Permissions setState={setState} />}
        </MainCon>
    )
}

const MainCon = styled.div`
    width: 100%;  
    background-color:#FAFAFA;
`;



export default UserManagement