import styled from "styled-components";

const DashboardStyle = styled.div`
    .naira{
        font-family: sans-serif;
    }

    .ii-card-header{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #242424;
        font-family: "Plus Jakarta Display";
    }
    
    .transact-card{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border: 1px solid #E4E7F8;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        align-items: flex-start;

        small{
            font-weight: 400;
            font-size: 14px;
            color: #5C5C5C;
        }
        h3{
            margin-top: 10px;
            font-weight: 700;
            margin-bottom: 0;
        }
    }

    .transact-card-small{
        background: #FFFFFF;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media only screen and (max-width: 767px){
            height: fit-content;
        }

        span{
            font-weight: 400;
            font-size: 14px;
            color: #5C5C5C;
        }
    }

    .black-card{
        background: #23252F;
        box-shadow: 0px 8px 24px rgba(123, 97, 255, 0.27);
        border-radius: 4px;
        height: fit-content;

        small{
            font-weight: 400;
            font-size: 14px;
            color: #fff;
        }
        h3{
            margin-top: 10px;
            font-weight: 700;
            margin-bottom: 0;
            color: #fff;
        }
    }
`

export default DashboardStyle