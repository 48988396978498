import Styles from "../styles/Styles";
import { Input } from "antd";
import { FaKey, FaLongArrowAltRight } from "react-icons/fa";
import * as yup from "yup"
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import React from "react"


interface FormData {
    token: string,
    newPassword: string,
    confirmNewPassword: string
}

const initialValues: FormData = {
    token: "",
    newPassword: "",
    confirmNewPassword: ""
}

const validationSchema = yup.object().shape({
    token: yup.string().required("Token cannot be blank"),
    newPassword: yup.string().nullable().required('Required').min(5, 'Invalid Password'),
    confirmNewPassword: yup.string().nullable()
        .required('Required')
        .oneOf([yup.ref('newPassword'), null], 'Passwords do not match'),
})


export default function ResetPassword() {
    const { token } = useParams()
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (fieldData: FormData, helpers) => {
            try {
                // const { data } = await ApiNoAuth.post("/user/reset-password", fieldData)
                // toast.success(data?.statusMessage || "Successful")
                navigate("/")
            } catch (error: any) {
                toast.error(
                    error?.response?.data?.statusMessage || 'An error occured'
                )
            }
        },
        validationSchema
    })

    React.useEffect(() => {
        formik.setFieldValue("token", token)
    }, [])

    return <Styles>
        <div className="card-section">
            <h3>Reset Password</h3>
            <FormikProvider value={formik}>
                <Input.Password
                    placeholder="New Password"
                    name="newPassword"
                    prefix={<FaKey style={{ color: "#858585" }} className="icon" />}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="large" className="mt-3 input-field" />
                <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='newPassword' />

                <Input.Password
                    placeholder="Confirm New Password"
                    name="confirmNewPassword"
                    prefix={<FaKey style={{ color: "#858585" }} className="icon" />}
                    value={formik.values.confirmNewPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="large" className="mt-3 input-field" />
                <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='confirmNewPassword' />

                <button
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="button"
                    onClick={() => formik.submitForm()}
                    className="ct-button mt-4 w-100">
                    {formik.isSubmitting ? <span className='spinner-border spinner-border-sm text-white'></span> : "Continue"}
                </button>
                <div className="d-flex justify-content-center mt-4">
                    <a href="/auth/register" className="login">Register <FaLongArrowAltRight className="ml-2" /></a>
                </div>
            </FormikProvider>
        </div>
    </Styles >
}