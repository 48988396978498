
import styled from "styled-components";
import { Modal, Avatar, Button } from 'antd';
import { FaTimes } from "react-icons/fa";

const ChatModal = ({ visible, onCancel }: any) => {
    return <Modal
        title=""
        footer={null}
        closable={false}
        width={358}
        style={{ left: 450 }}
        bodyStyle={{ padding: 0, borderRadius: "4px", height: "455px"}}
        className="pipeline-card"
        visible={visible} 
        onCancel={onCancel} 
        onOk={onCancel}

    >
      <Con>
          <header>
            <h3>Chat with us</h3>
            <p>Typically replies within 2 hours</p>
            <div className="close">
              <Avatar  onClick={() => visible(false)} size={20} style={{ background: "#D0D3D8", cursor: "pointer" }} icon={<FaTimes className="close-icon txt-primary txt-12 mt-n1" />} />
            </div>
          </header>

          <main>
              <div className="alaje">
                <div id="alajechat" >
                  <img src="/images/dashboard/alajechat.svg" alt="img"/>
                </div>

                <div className="main-chat">
                  <img src="/images/dashboard/alajelogo.svg" alt="img" />
                  <p>Hello, welcome to hydrogen chat</p>
                </div>

                <div className="reply">
                  <p>Users message goess here and this is dummy content to show</p>
                </div>

                <div className="main-chat">
                  <img src="/images/dashboard/alajelogo.svg" alt="img" />
                  <p>Hello, welcome to hydrogen chat</p>
                </div>
                

                <div className="chat">
                  <input type="text" placeholder="Reply here ..." />
                  <div id="attach">
                    <img src="/images/dashboard/attach.svg" alt="img" />
                  </div>
                  <div id="smile">
                    <img src="/images/dashboard/smile.svg" alt="img" />
                  </div>
                </div>
              </div>
          </main>
      </Con>
    </Modal>
}

const Con = styled.div`
  header {
    .close {
      margin-top: -130px;
      margin-left: 310px;
    }
  }

  main {
    width: 326px;
    height: 358px;
    margin-left: -10px;
    margin-top: 80px;
    box-shadow: 0px 7px 16px rgba(81, 65, 164, 0.37), 0px 1.4px 2.6px rgba(81, 65, 164, 0.185);
    border-radius: 4px;
    padding: 19px;

    .alaje {
      #alajechat {
        border-bottom: 1px solid #D6DBF5;
        padding-bottom: 10px;
      }

      .main-chat {
        margin-top: 28px;
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          margin-top: 7px;
          padding: 12px;
          width: 218px;
          height: 38px;
          background: #F3F4F5;
          border-radius: 4px 4px 8px 0px;
          font-weight: 400;
          font-size: 12px;
        }
      }
      .reply {
        margin-left: 70px;
        padding: 12px;
        width: 221px;
        height: 54px;
        background: #D78B07;
        border-radius: 4px 4px 8px 0px;

        p {
          font-weight: 400;
          font-size: 12px;
          color: #fff;
        }
      }
    }

    .chat {
      display: flex;
      align-items: center;
      margin-top: 25px;

      input {
        padding: 12px;
        width: 222px;
        height: 38px;
        background: #F3F4F5;
        border-radius: 4px 4px 8px 0px;
        outline: none;
        border: none;

        
      }
      #attach {
        margin-left: 20px;
      }
      #smile {
        margin-left: 15px;
      }
    }
  }
`
 
export default ChatModal;