import { FaCaretDown } from "react-icons/fa"
import { components } from "react-select"

export default function ReactSelectStyling(noBg?: any) {
    return {
        control: (base: any, state: any) => ({
            ...base,
            border: "1px solid #D0D3D8",
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: "1px solid #D0D3D8",
            },
            background: noBg ? "none" : "#fff",
            fontSize: "13px"
        }),
        option: (styles: any, state: any) => ({
            ...styles,
            fontSize: "13px",
            '&:active': {
                background: "#DF9E30",
                color: "#fff"
            },
            // cursor: state.data.value === "" ? "not-allowed" : "",
            background: state.isSelected ? "#fff" : "#fff",
            color: "#000"
        }),
        placeholder: (styles: any) => ({
            ...styles,
            fontSize: "13px"
        }),
        indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
        noOptionsMessage: (styles: any) => ({
            ...styles,
            fontSize: "13px"
        })
    }
}

const CaretDownIcon = () => {
    return <FaCaretDown style={{ color: "#000" }} />
}

export const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    )
}