import styled from "styled-components";

const Styles = styled.div`
    width: 100%;
    .errorSpan{
        color: red;
        font-size: 12px;
    }
    .card-section{
        width: 500px;
        margin: 0 auto;
        @media only screen and (max-width: 600px){
            width: 100%;
        }

        &.success{
            display: flex;
            flex-direction: column;
            background: #fff;
            padding: 30px 10px;
            border-radius: 5px;
            h6{
                color: #434D64;
                text-align: center;
            }
            p{
                text-align: center;
                color: #727A8B;
            }
        }

        .subHeader{
            color: #5C5C5C;
        }

        .or-register{
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            color: #6C4604;
            cursor: pointer;
        }

        .login{
            color: #343434;

            &.underline{
                &:hover{
                    text-decoration: underline;
                }
            }
        }

        .ct-button{
            all: unset;
            background: #DF9E30;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 600;
            color: #FFFFFF;
            padding-top: 15px;
            padding-bottom: 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #DF9E30;
            transition: 0.15s;

            &:disabled{
                background: #D88B07;
                opacity: 0.5;
            }
        }

        .input-field{
            padding-top: 14px;
            padding-bottom: 14px;
            border-color: #D6DBF5;
            border-radius: 4px;
            box-shadow: none !important;

            input{
                font-size: 14px;
                color: #858585;
                padding-left: 8px;
                ::placeholder{
                    font-size: 14px;
                    color: #858585;
                }
            }
        }

        .selection-card{
            cursor: pointer;
            width: 100%;
            background: #FFFFFF;
            border: 2px solid #E4E7F8;
            border-radius: 8px;
            padding: 18px;
            display: flex;
            align-items: center;

            &:hover,
            &.active{
                border: 2px solid #C8CFF2;
            }

            &.active{
                .circle{
                    background: #23252F;
                    border: 3px solid white;
                    outline-color: #23252F;
                }
            }

            .divider{
                height: 50px;
            }

            h5{
                margin-bottom: 0;
                color: #242424;
            }

            small{
                color: #5C5C5C;
            }

            .circle{
                width: 20px;
                height: 20px;
                border-radius: 20px;
                outline: 1px solid #BBC3EE;
            }
        }
    }

`

export default Styles