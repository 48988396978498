import styled from "styled-components";
import React from "react";
import { useState } from "react";
import ChatPage from "./ChatPage";
import Chatbox from "./Chatbox";

const ChatMessaging = ({ data }: any ) => {

    const [view, setView] = useState(1);


    return ( 
        <Con>
             <header>
                 <h2>Chat Support</h2>
                 <button><p>New Chat</p></button>
             </header>

             <Display>
                 {view==1 && <ChatPage setView={setView} />}
                 {view==2 && <Chatbox setView={setView} />}
             </Display>
        </Con>
     );
}

const Con = styled.div`
  padding-left: 27px;
  padding-top: 40px;

 
     header {
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 17px 24px;
         width: 1180px;
         height: 78px;
         background: #fff;
         border: 1px solid #E4E7F8;
         border-radius: 4px;
 
         h2 {
             font-weight: 500;
             font-size: 28px;
             line-height: 42px;
             color: #0B0B0B;
         }
 
         button {
             display: flex;
             align-items: center;
             justify-content: center;
             padding: 14.5px 30.5px;
             width: 150px;
             height: 44px;
             background: #DF9E30;
             border-radius: 2px;
             color: #fff;
             border: none;
 
             p {
                 font-weight: 600;
                 font-size: 15px;
         }
     }
`

const Display = styled.div`

`


 
export default ChatMessaging;