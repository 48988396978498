import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from "recharts";

const data = [
    {
        name: "Jan",
        bar1: 12,
        bar2: 23,
        bar3: 18,
    },
    {
        name: "Feb",
        bar1: 15,
        bar2: 19,
        bar3: 21,
    },
    {
        name: "Mar",
        bar1: 20,
        bar2: 10,
        bar3: 15,
    },
    {
        name: "Apr",
        bar1: 8,
        bar2: 12,
        bar3: 17,
    },
    {
        name: "May",
        bar1: 18,
        bar2: 15,
        bar3: 10,
    },
    {
        name: "Jun",
        bar1: 14,
        bar2: 22,
        bar3: 13,
    },
    {
        name: "Jul",
        bar1: 25,
        bar2: 13,
        bar3: 19,
    },
    {
        name: "Aug",
        bar1: 11,
        bar2: 18,
        bar3: 24,
    },
    {
        name: "Sep",
        bar1: 16,
        bar2: 20,
        bar3: 9,
    },
    {
        name: "Oct",
        bar1: 23,
        bar2: 11,
        bar3: 14,
    },
    {
        name: "Nov",
        bar1: 9,
        bar2: 16,
        bar3: 22,
    },
    {
        name: "Dec",
        bar1: 21,
        bar2: 14,
        bar3: 11,
    },
];


const CustomizedXAxisTick = ({ x, y, payload }: any) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={-11}
                y={0}
                dy={16}
                fill="#737A9D"  // change color here
                fontSize={12} // change font size here
                style={{ fontFamily: "Plus Jakarta Text" }} // change font family here
            >
                {payload.value}
            </text>
        </g>
    );
};

const CustomChart = () => {
    return (
        <div style={{ width: "100%", height: "310px" }} className="barchart-holder">
            <ResponsiveContainer>
                <BarChart
                    barSize={25}
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid stroke="#737A9D" strokeWidth={0.2} horizontal={true} vertical={false} />
                    <XAxis
                        tickLine={{ stroke: "#737A9D", strokeWidth: 0.5 }}
                        axisLine={{ stroke: "#737A9D", strokeWidth: 0.2 }}
                        tick={<CustomizedXAxisTick />} dataKey="name" />
                    <Bar dataKey="bar1" stackId="a" fill="#3A3D4E" />
                    <Bar dataKey="bar2" stackId="a" fill="#9099C4" />
                    <Bar radius={[12, 12, 0, 0]} dataKey="bar3" stackId="a" fill="#E4E7F8" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CustomChart;
