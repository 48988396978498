import styled from "styled-components";
import { useState } from "react";
import Modals from "./Modal";

const Feedback = ({display, setDisplay}: any) => {

    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true); 
    };

    return ( 
        <Con>
            <nav>
                <div className="path">
                    <img src="/images/dashboard/arrow-left.svg" alt="img" onClick={() => setDisplay(3)} />
                    <p>Support Portal/Knowledge base/<span>Categories</span></p>
                </div>
                <div className="filter">
                    <p>Filter:</p>
                    <div className="box">
                        <p>2023</p>
                        <img src="/images/dashboard/arrown-down.svg" alt="img" />
                    </div>
                </div>
            </nav>

            <Main>
                <aside>
                    <div className="search-box">
                        <img src="/images/dashboard/search.svg" alt="img" />
                        <input type="search" placeholder="Search..." />
                    </div>

                    <div className="help-list">
                        <p id="support">Support Articles</p>
                        <p id="faq">FAQs</p>
                        <p id="user">User guides</p>
                        <p id="guide">Troubleshoot guide</p>
                        <p id="blogs">Blogs</p>
                    </div>
                </aside>

               <main>
                   <h2>Help us improve our service</h2>
                   <p>Please rate your experience with us</p>

                    <div className="rating">
                        <p>1</p>
                        <p>2</p>
                        <p>3</p>
                        <p>4</p>
                        <p>5</p>
                        <p>6</p>
                        <p>7</p>
                        <p>8</p>
                        <p>9</p>
                        <p>10</p>
                    </div>

                    <div className="feed-box">
                        <p>Give us feedback</p>
                        <input type="text" placeholder="Type here..." />
                    </div>

                    <button onClick={showModal}><p>Submit Feedback</p><img src="/images/dashboard/arrow-right-white.svg" alt="icon" /></button>
               </main>
               <Modals visible={visible} onCancel={() => setVisible(false)} />
            </Main>
     </Con>
     );
}

const Con = styled.div`
    margin-top: 39.5px;

   nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .path {
        display: flex;
        align-items: center;
        gap: 1rem;

        p {
            padding: 10px;
            width: 342px;
            height: 37px;
            background: #FFFFFF;
            border: 1px solid #EEECF6;
            border-radius: 4px;

            span {
                font-weight: 700;
            }
        }
    }

    .filter {
        display: flex;
        align-items: center;
        gap: 10px;

        .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;          
            width: 200px;
            height: 40px;
            border: 1px solid #EEECF6;
            border-radius: 4px;
        }
    }
   }
`

const Main = styled.div`
   margin-top: 43px;
   display: flex;
   gap: 24px;

   aside {
    width: 240px;
    height: 1059px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;



        .search-box {
            width: 192px;
            height: 42px;
            background: #F3F4F5;
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 19px;
            gap: 9px;
            font-size: 14px;

            input {
                width: 100px;
                outline: none;
                border: none;
                margin-top: 5px;
                background: #F3F4F5;
                display: flex;
                align-items: center;
            }
        }

        .help-list {
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid #E7E9EB;

            p {
                margin-top: 14px;
                margin-bottom: 18px;
                font-weight: 400;
                font-size: 14px;
            }

            #support {
                width: 192px;
                height: 45px;
                background: #FFF5E3;
                border-radius: 8px;
                display: flex;
                justify-content: left;
                padding-left: 16px;
                align-items: center;
                font-weight: 800;
                font-size: 14px;
            }
        }
    
   }

   main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 24px;
    width: 1200px;
    height: 532px;
    background: #FFFFFF;
    border-radius: 8px;

        h2 {
            font-weight: 800;
            font-size: 18px;
            color: #14213D;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            color: #434D64;
        }

        .rating {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            margin-top: 17px;

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #D0D3D8;
                border-radius: 43px;
                cursor: pointer;
            }
        }

        .feed-box {
            margin-top: 30px;

            p {
                font-weight: 400;
                font-size: 14px;
                color: #434D64;
            }

            input {
                width: 890px;
                height: 125px;
                background: #F3F4F5;
                border-radius: 8px;
                outline: none;
                border: none;
                display: flex;
                flex-wrap: wrap;
                text-align: left;
                padding: 10px;
                
            }
        }

        button {
            margin-top: 30px;
            display: flex;
            align-items: center;
            margin-left: auto;
            padding: 14.5px 30.5px;
            gap: 10px;
            width: 210px;
            height: 44px;
            background: #DF9E30;
            border-radius: 2px;
            color: #fff;
            border: none;

            p {
                font-weight: 600;
                font-size: 15px;
                color: #fff;
            }

            img {
                color: #fff;
                margin-top: -7px;
            }
        }
   }

`
 

 
export default Feedback;