import Styles from "../styles/Styles";
import { Input } from "antd";
import { useState } from "react";
import { ReactComponent as Mail } from "../images/Mail.svg";
import { FaLongArrowAltRight } from "react-icons/fa";
import * as yup from "yup"
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { toast } from "react-toastify";


interface FormData {
    email?: string,
}

const initialValues: FormData = {
    email: "",
}

const validationSchema = yup.object().shape({
    email: yup.string().required("Email cannot be blank").email("Email is not valid")
})


export default function ResendVerification() {
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (data: FormData, helpers) => {
            try {
                // await ApiNoAuth.post('/user/resend-verification-link', data)
                toast.success("Verification link sent!")
                helpers.resetForm()
            } catch (error: any) {
                toast.error(error.response?.data?.statusMessage || `An error occured`)
            }
        },
        validationSchema
    })

    const [state, setState] = useState({
        stage: 1,
        mode: "email"
    })
    return <Styles>
        <div className="card-section">
            <h3>Resend Verification Link</h3>
            <FormikProvider value={formik}>

                <Input
                    placeholder="Email Address"
                    prefix={<Mail className="icon" />}
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="large" className="mt-3 input-field" />
                <ErrorMessage render={(msg) => <div className='errorSpan'>{msg}</div>} name='email' />

                <button
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="button"
                    onClick={() => formik.submitForm()}
                    className="ct-button mt-4 w-100">
                    {formik.isSubmitting ? <span className='spinner-border spinner-border-sm text-white'></span> : "Continue"}
                </button>
                <div className="d-flex justify-content-center mt-4">
                    <a href="/" className="login">Login <FaLongArrowAltRight className="ml-2" /></a>
                </div>
            </FormikProvider>
        </div>
    </Styles >
}