import { url } from 'inspector';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import active from '../../../assets/active.svg'
import inactive from '../../../assets/unactive.svg'

const Grid = () => {

    
    const navigate = useNavigate();

    function handleClick() {
      window.location.href = '/dashboard/platform';
    }

    const gridcard = [
        {
            id: 1,
            name: "Alaje Government",
            status: "Active",
            date: "12/12/22",
            img: active,
            revenue: "$100,000",
            user: "200",
            block: "Block",
            view: "View"
        },
        {
            id: 2,
            name: "Alaje Citizens",
            status: "Active",
            date: "12/12/22",
            img: active,
            revenue: "$100,000",
            user: "200",
            block: "Block",
            view: "View"
        },
        {
            id: 3,
            name: "School Management",
            status: "Inactive",
            date: "12/12/22",
            img: inactive,
            revenue: "$100,000",
            user: "200",
            block: "Block",
            view: "View"
        },
    ];

    return ( 

        
        <Con>
            <GridBox>
                <div className="grid-con">
                    {gridcard.map(({name, status, date, revenue, user, block, view, img})=>(
                      <div id='grid-con'>
                            <div className="top">
                                <img src="/images/dashboard/more.svg" alt="icon" id='more'/>
                                <div id="bank">
                                <img src="/images/dashboard/bank.svg" alt="img" />
                                </div>
                                <p id='name'>{name}</p>
                                <div className="status">
                                    <img src={img} alt="img" />
                                    <p>{status}</p>
                                </div>
                            </div>
                            <div className="user">
                                <p>{user}</p>
                                <p>Users</p>
                            </div>
                      </div> 
                    ))}
                </div>
            </GridBox>
             
        </Con>
      );
 }
 
 const Con = styled.div`
  height: 100vh;
 `
 
 const GridBox= styled.div`

    .grid-con {
        display: flex;
        gap: 32px;

        #grid-con {
            width: 295px;
            height: 310px;
            background: #FFFFFF;
            border: 1px solid #E4E7F8;
            border-radius: 4px;
            margin-top: 32px;
            text-align: center;

            .top {
                padding: 20px;
            }

            #more {
                margin-left: 230px;
            }

            #bank {
                width: 124px;
                height: 124px;
                background: #EFF1FB;
                border: 2px solid #D6DBF5;
                text-align: center;
                border-radius: 200px;
                margin-left: 70px;

                img {
                    width: 44px;
                    height: 44px;
                    margin-top: 35px;

                }
            }

            #name {
                padding-top: 24px;
                font-weight: 600;
                font-size: 18px;
            }

            .status {
                display: flex;
                align-items: center;
                gap: 7px;
                font-weight: 400;
                font-size: 14px;
                text-align: center;
                color: #575C76;
                margin-left: 100px;
                margin-top: 10px;
            }

            .user {
                display: flex;
                align-items: center;
                gap: 3px;
                justify-content: center;
                border-top: 1.5px solid #E4E7F8;
                padding: 20px;

                >p {
                    font-weight: 700px;
                }
            }
            
        }
    }
   
 `
export default Grid;