import styled from "styled-components";
import help2 from "../../../assets/help2.svg";


const Troubleshoot = ({display, setDisplay}: any) => {

    const articleArray= [
        {
            header: "How to set up your account",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            date: "12 Feb, 2022",
        },
        {
            header: "How to set up your account",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            date: "12 Feb, 2022",
        },
        {
            header: "How to set up your account",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            date: "12 Feb, 2022",
        },
        {
            header: "How to set up your account",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            date: "12 Feb, 2022",
        },
        {
            header: "How to set up your account",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            date: "12 Feb, 2022",
        },
        {
            header: "How to set up your account",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            date: "12 Feb, 2022",
        }
    ]
  

    return ( 
        <Con>
            <nav>
                <div className="path">
                    <img src="/images/dashboard/arrow-left.svg" alt="img" onClick={() => setDisplay(2)} />
                    <p>Support Portal/Knowledge base/<span>Categories</span></p>
                </div>
                <div className="filter">
                    <p>Filter:</p>
                    <div className="box">
                        <p>2023</p>
                        <img src="/images/dashboard/arrown-down.svg" alt="img" />
                    </div>
                </div>
            </nav>

            <Main>
                <aside>
                    <div className="search-box">
                        <img src="/images/dashboard/search.svg" alt="img" />
                        <input type="search" placeholder="Search..." />
                    </div>

                    <div className="help-list">
                        <p id="support">Support Articles</p>
                        <p id="faq">FAQs</p>
                        <p id="user">User guides</p>
                        <p id="guide">Troubleshoot guide</p>
                        <p id="blogs">Blogs</p>
                    </div>
                </aside>

               <main>
                    <div className="article">
                        <div className="left">
                            <h1>How to troubleshoot a slow computer</h1>
                            <p id="author">Author: Jeremy Baker</p>
                            <p id="date">published on: 3rd March, 2023</p>
                            <img src={help2} alt="img" />

                            <h2>Introduction:</h2>
                            <p id="intro">If you're experiencing issues with your computer, don't panic! There are a few simple steps you can take to troubleshoot and solve common problems. In this guide, we'll cover some of the most common computer issues and provide step-by-step instructions to help you get your computer back up and running.</p>

                           <div className="list">
                            <h3>Computer Won't Start</h3>
                                <ul>
                                    <li>Check that the power cord is securely plugged in and the outlet is working.</li>
                                    <li> Make sure there are no USB drives, CDs, or DVDs in the computer.</li>
                                    <li>Check that the monitor is turned on and properly connected to the computer.</li>
                                    <li> If the computer still won't start, try a different power outlet or power cord.</li>
                                    <li> If the problem persists, it may be a hardware issue and you should contact a professional for help.</li>
                                </ul>
                           </div>

                           <div className="list">
                            <h3> Slow Performance</h3>
                                <ul>
                                    <li>Close any unnecessary programs and browser tabs.</li>
                                    <li>Run a virus scan to make sure there are no viruses or malware slowing down your computer.</li>
                                    <li> Delete any unnecessary files or programs to free up disk space.</li>
                                    <li> If the problem persists, try upgrading your RAM or hard drive.</li>
                                </ul>
                           </div>

                           <div className="list">
                            <h3>Blue Screen of Death (BSOD)</h3>
                                <ul>
                                    <li> Note the error message that appears on the screen and look it up online for troubleshooting steps.</li>
                                    <li> Check that your computer's drivers are up to date.</li>
                                    <li>Run a virus scan to make sure there are no viruses or malware causing the issue.</li>
                                    <li>Run a virus scan to make sure there are no viruses or malware causing the issue.</li>
                                </ul>
                           </div>

                           <div className="list">
                            <h3>Internet Connection Issues</h3>
                                <ul>
                                    <li>Make sure that the computer is connected to the internet.</li>
                                    <li> Restart the computer and router/modem.</li>
                                    <li> Check that the network drivers are up to date.</li>
                                    <li>  Try connecting to a different Wi-Fi network or using a wired connection.</li>
                                    <li> If the problem persists, contact your internet service provider.</li>
                                </ul>
                           </div>

                           <h3>Conclusion:</h3>
                           <p> These are just a few common computer issues and troubleshooting steps. If you're still experiencing issues, don't hesitate to contact a professional for help. We hope this guide has been helpful in getting your computer back up and running.</p>

                           <button onClick={() => setDisplay(4)}>Give feedback</button>

                        </div>
                        <div className="right">
                            <h2>Similar Articles</h2>

                            {articleArray.map(({header, content, date})=> (
                            <div>
                                <div className="help-box">
                                    <p id="head">{header}</p>
                                    <p id="content">{content}</p>
                                    <p id="dates">{date}</p>
                                </div>
                            </div>
                            ))}

                        </div>
                    </div>
               </main>
            </Main>
        </Con>
     );
}

const Con = styled.div`
    margin-top: 39.5px;

   nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .path {
        display: flex;
        align-items: center;
        gap: 1rem;

        p {
            padding: 10px;
            width: 342px;
            height: 37px;
            background: #FFFFFF;
            border: 1px solid #EEECF6;
            border-radius: 4px;

            span {
                font-weight: 700;
            }
        }
    }

    .filter {
        display: flex;
        align-items: center;
        gap: 10px;

        .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;          
            width: 200px;
            height: 40px;
            border: 1px solid #EEECF6;
            border-radius: 4px;
        }
    }
   }
`

const Main = styled.div`
   margin-top: 43px;
   display: flex;
   gap: 24px;

   aside {
    width: 240px;
    height: 1059px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;



        .search-box {
            width: 192px;
            height: 42px;
            background: #F3F4F5;
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 19px;
            gap: 9px;
            font-size: 14px;

            input {
                width: 100px;
                outline: none;
                border: none;
                margin-top: 5px;
                background: #F3F4F5;
                display: flex;
                align-items: center;
            }
        }

        .help-list {
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid #E7E9EB;

            p {
                margin-top: 14px;
                margin-bottom: 18px;
                font-weight: 400;
                font-size: 14px;
            }

            #support {
                width: 192px;
                height: 45px;
                background: #FFF5E3;
                border-radius: 8px;
                display: flex;
                justify-content: left;
                padding-left: 16px;
                align-items: center;
                font-weight: 800;
                font-size: 14px;
            }
        }
    
   }

   main {
    .article {
        display: flex;
        gap: 24px;

        .left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px 24px;
            gap: 24px;
            background: #FFFFFF;
            border: 1px solid #EEECF6;
            border-radius: 8px;
            width: 600px;
            padding-bottom: 40px;

            h1 {
                font-weight: 800;
                font-size: 32px;
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                color: #434D64;
            }

  

            #author, #date {
                font-weight: 400;
                font-size: 14px;
                color: #434D64;
                margin-top: -20px;
            }

            img {
                width: 550px;
                height: 259px;
            }

            h2 {
                font-weight: 600;
                font-size: 18px;
            }

            h3 {
                font-weight: 600;
                font-size: 16px;
            }

            p {
                margin-top: -30px;
                font-size: 14px;
                font-weight: 400;
                color: #727A8B;
                width: 550px;
            }

            .list {
                h3 {
                    font-weight: 600;
                    font-size: 15px;
                }

                ul {
                    margin-left: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #727A8B;
                }
            }

            button {
                padding: 16px 24px;
                width: 132px;
                height: 51px;
                border: 1px solid #CBC6E4;
                border-radius: 4px;
                font-weight: 400;
                font-size: 12px;
                color: #5141A4;
                margin-left: auto;
            }
        }

        .right {
            h2 {
                font-weight: 800;
                font-size: 24px;
                color: #14213D;
            }

            .help-box {
                padding: 24px;
                width: 285px;
                height: 190px;
                background: #FFFFFF;
                border: 1px solid #EEECF6;
                border-radius: 6px;

                #head {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                    color: #434D64;
                }

                #content {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    color: #727A8B;
                    margin-top: 20px;
                }

                #dates {
                    padding: 4px;
                    width: 83px;
                    height: 25px;
                    background: #EEECF6;
                    border-radius: 2px;
                    font-size: 12px;
                    color: #110C35;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }
            }
        }
    }
   }

`
 
export default Troubleshoot;