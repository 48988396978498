import React from 'react'
import styled from "styled-components";
import NewNav from '../components/defaults/NewNav';

const NewDashLayout: React.FC<any> = ({ children }) => {

    return (
        <Con>
            <NewNav />
            <Main>
                {children}
            </Main>
        </Con>
    )
}

const Con = styled.div`
    width: 100%;   
    background-color:#FAFAFA; 
    p{
        margin:0;
    }
`;

const Main = styled.div`
    width: 100%;   
    padding:0 70px; 
    background-color:#FAFAFA;
    @media (max-width: 700px) { 
        padding:0 20px;
    }
`;



export default NewDashLayout