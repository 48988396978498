import { Avatar, Drawer } from "antd";
import DashboardTableStyle from "assets/scss/components/DashboardTableStyle";
import SideModalHeader from "components/common/SideModalHeader";
import { useState } from "react";
import ViewMore from "./ViewMore";

export default function Portal() {
    const [openModal, setOpenModal] = useState(false)
    const onModalClose = () => setOpenModal(!openModal)
    return <>
        {openModal && <Drawer
            title={
                <SideModalHeader>
                    <h1>Activity Log</h1>
                    <p onClick={onModalClose}>Close</p>
                </SideModalHeader>
            }
            placement="right"
            closable={false}
            onClose={onModalClose}
            open={openModal}
            width="60%"
        >
            <ViewMore />
        </Drawer>}
        <DashboardTableStyle>
            <div className="table-responsive">
                <table className="w-100 h-100">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Activity</th>
                            <th>Time</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[1, 2, 3, 4, 2].map((val, idx) =>
                            <tr key={idx}>
                                <td>
                                    <div className="employee">
                                        <Avatar src={"https://xsgames.co/randomusers/avatar.php?g=male"} size={35} />
                                        <div className="info">
                                            <p className="name">Philip Bassey</p>
                                            <small>sunnyphilip51@gmail.com</small>
                                        </div>
                                    </div>
                                </td>
                                <td>Made new payment for - <i>NURTW</i></td>
                                <td>1 Day ago</td>
                                <td>12/12/22</td>
                                <td className="btns">
                                    <span onClick={() => setOpenModal(true)}>More</span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </DashboardTableStyle>
    </>
}