import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styled from "styled-components";
import { Col, Row } from "antd";
import { ReactComponent as WalletIcon } from "./images/walletSvg.svg"
import { ReactComponent as DollarIcon } from "./images/dollarSvg.svg"
import { ReactComponent as RefreshIcon } from "./images/refreshSvg.svg"
import { ReactComponent as QuickDollarIcon } from "./images/quickActionDollar.svg"
import { barData } from '../../utils/data'



const Government = () => {
    return (
        <DashboardStyle>
            <Row className="mt-4" gutter={[20, 20]}>
                <Col xs={24} sm={12} lg={8}>
                    <div className="transact-card">
                        <WalletIcon />
                        <div className="ml-2">
                            <small>Total no. of users</small>
                            <h3>20,000</h3>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                    <div className="transact-card">
                        <WalletIcon />
                        <div className="ml-2">
                            <small>Total no. of payments received</small>
                            <h3>66,000</h3>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                    <div className="transact-card">
                        <DollarIcon />
                        <div className="ml-2">
                            <small>Total Revenue</small>
                            <h3>₦996,028,975</h3>
                        </div>
                    </div>
                </Col>
            </Row>
            <ChartCon>
                <div className='top'>
                    <p>NUMBER OF PAYMENTS RECEIVED PER DAY</p>
                </div>
                <ResponsiveContainer width="80%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={barData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pv" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </ChartCon>
            <ChartCon>
                <div className='top'>
                    <p>NUMBER OF PAYMENTS RECEIVED PER DAY</p>
                </div>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={barData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pv" fill="#8884d8" />
                        <Bar dataKey="uv" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </ChartCon>
        </DashboardStyle>
    )
}


const DashboardStyle = styled.div`
    .naira{
        font-family: sans-serif;
    }

    .ii-card-header{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #242424;
        font-family: "Plus Jakarta Display";
    }
    
    .transact-card{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border: 1px solid #E4E7F8;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        align-items: flex-start;

        small{
            font-weight: 400;
            font-size: 14px;
            color: #5C5C5C;
        }
        h3{
            margin-top: 10px;
            font-weight: 700;
            margin-bottom: 0;
        }
    }

    .transact-card-small{
        background: #FFFFFF;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media only screen and (max-width: 767px){
            height: fit-content;
        }

        span{
            font-weight: 400;
            font-size: 14px;
            color: #5C5C5C;
        }
    }

    .black-card{
        background: #23252F;
        box-shadow: 0px 8px 24px rgba(123, 97, 255, 0.27);
        border-radius: 4px;
        height: fit-content;

        small{
            font-weight: 400;
            font-size: 14px;
            color: #fff;
        }
        h3{
            margin-top: 10px;
            font-weight: 700;
            margin-bottom: 0;
            color: #fff;
        }
    }
`
const ChartCon = styled.div`
    width:100%;  
    background: #FFFFFF; 
    border: 1px solid #E4E7F8;
    border-radius: 4px;
    margin: 20px 0;
    padding:20px;
    .top{
        margin-bottom:20px;
        p{
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 120%; 
            text-transform: uppercase; 
            color: #434D64;
        }
    }
`

export default Government