import styled from "styled-components";

const DashboardTableStyle = styled.div`
    .table-responsive{
        background: #fff;
        border: 1px solid #EAECF0;
        border-radius: 4px;
        height: fit-content;

        thead{
            tr{
                th{
                    padding: 20px 20px;
                    background: #F9FAFB;
                    text-transform: uppercase;
                    border-top: 1px solid #EAECF0;
                    border-bottom: 1px solid #EAECF0;
                    color: #667085;
                    white-space: nowrap;
                    font-size: 12px;
                }
            }
        }

        tbody{
            tr{
                td{
                    padding: 13px 20px;
                    border-bottom: 1px solid #EAECF0;
                    color: #667085;
                    font-size: 13px;

                    .employee{
                        display: flex;
                        align-items: flex-start;

                        .info{
                            margin-left: 10px;
                            .name{
                                text-transform: uppercase;
                                margin-bottom: 0;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 13px;
                                color: #23252F;
                            }
    
                            small{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                color: #575C76;
                            }
                        }
                    }

                    &.btns{
                        span{
                            border: 1px solid #C8CFF2;
                            border-radius: 4px;
                            font-size: 10px;
                            padding: 5px 15px;
                            cursor: pointer;

                            &.delete{
                                color: #EB5757;
                                border-color: #EB5757;
                            }
                        }
                    }
                }
            }
        }
    }
`


export default DashboardTableStyle