import { Col, Row } from "antd";
import { ReactComponent as WalletIcon } from "./images/walletSvg.svg"
import { ReactComponent as DollarIcon } from "./images/dollarSvg.svg"
import { ReactComponent as RefreshIcon } from "./images/refreshSvg.svg"
import { ReactComponent as QuickDollarIcon } from "./images/quickActionDollar.svg"
import DashboardStyle from "./styles/DashboardStyle";
import RecentlyAddedUsers from "./subComponents/RecentlyAddedUsers";
import RecentActivity from "./subComponents/RecentActivity";
import UsageAnalytics from "./subComponents/UsageAnalytics";

export default function Dashboard() {
    return <DashboardStyle>
        <Row className="mt-4" gutter={[20, 20]}>
            <Col xs={24} sm={12} lg={4}>
                <div className="transact-card">
                    <WalletIcon />
                    <div className="ml-2">
                        <small>Total Users</small>
                        <h3>0</h3>
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={12} lg={4}>
                <div className="transact-card">
                    <WalletIcon />
                    <div className="ml-2">
                        <small>Active Users</small>
                        <h3>200</h3>
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={12} lg={4}>
                <div className="transact-card">
                    <DollarIcon />
                    <div className="ml-2">
                        <small>Inactive Users</small>
                        <h3>200</h3>
                    </div>
                </div>
            </Col>

            <Col style={{ borderLeft: "1px solid #CECECE" }} xs={24} lg={12}>
                <Row style={{ height: "100%" }} gutter={[0, 20]}>
                    <Col className="transact-card-small py-3" xs={24} md={8}>
                        <QuickDollarIcon />
                        <span>Add new user</span>
                    </Col>
                    <Col className="transact-card-small py-3" xs={24} md={8}>
                        <QuickDollarIcon />
                        <span>Access Analytics</span>
                    </Col>
                    <Col className="transact-card-small py-3" xs={24} md={8}>
                        <QuickDollarIcon />
                        <span>Add new platform</span>
                    </Col>
                </Row>
            </Col>

            <Col className="py-4 border-bottom" span={24}>
                <Row gutter={[25, 20]}>
                    <Col xs={24} sm={12}>
                        <h6 className="ii-card-header">Alaje government</h6>
                        <div className="black-card py-4 px-3">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-start col-md-5">
                                        <DollarIcon transform="scale(1.4)" />
                                        <div className="ml-3">
                                            <small>Total Users</small>
                                            <h3>200</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 mt-md-0 d-flex align-items-start col-md-7">
                                        <DollarIcon transform="scale(1.4)" />
                                        <div className="ml-3">
                                            <small>Total Revenue</small>
                                            <h3><span className="naira">₦</span>156,000</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <h6 className="ii-card-header">Alaje citizen</h6>
                        <div className="black-card py-4 px-3">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-start col-md-5">
                                        <DollarIcon transform="scale(1.4)" />
                                        <div className="ml-3">
                                            <small>Total Users</small>
                                            <h3>200</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex mt-4 mt-md-0 align-items-start col-md-7">
                                        <DollarIcon transform="scale(1.4)" />
                                        <div className="ml-3">
                                            <small>Total Revenue</small>
                                            <h3><span className="naira">₦</span>156,000</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>

        <div className="mt-5">
            <RecentlyAddedUsers />
        </div>

        <Row className="py-4" gutter={[25, 20]}>
            <Col xs={24} md={15}>
                <UsageAnalytics />
            </Col>
            <Col xs={24} md={9}>
                <RecentActivity />
            </Col>
        </Row>

    </DashboardStyle>
}